import React, { useEffect, useState } from "react";
// routes
import Router from "./routes";
// theme, Mui
import ThemeProvider from "./theme";
import { IconButton, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// components
import ScrollToTop from "./components/ScrollToTop";
//libs
import { SnackbarProvider } from "notistack";
//styles
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "src/assets/css/style.css";
import "src/doneSrc/assets/css/style.css";
import "react-datepicker/dist/react-datepicker.css";
// scrollbar-styles
import "simplebar/src/simplebar.css";
import { ContextSocket } from "./Context/Context-Sockets/ContextSockets";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { projectMode } from "./config/config";

// ----------------------------------------------------------------------

export default function App() {
  const notiStackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notiStackRef.current.closeSnackbar(key);
  };
  const { pathname } = useLocation();
  console.log(projectMode, "---projectMode");
  if (projectMode !== "dev" && window.location.hostname !== "localhost") {
    console.log = () => {};
  }
  useEffect(() => {
    if (
      pathname != "/expense" &&
      !pathname.match("/expense/detail-expense/") &&
      !pathname.match("/expense/edit-expense/")
    ) {
      let data = {
        expense_category: "",
        date_from: "",
        date_to: "",
        search: "",
        payment_method: "",
      };
      localStorage.setItem("ExpenseListSearch", JSON.stringify(data));
    }
    if (
      pathname != "/company-assets" &&
      !pathname.match("/company-assets/details-asset/") &&
      !pathname.match("/company-assets/edit-asset/") &&
      !pathname.match("/company-assets/add-repair-details-asset/") &&
      !pathname.match("/company-assets/repair_details-asset/") &&
      !pathname.match("/company-assets/create-assets")
    ) {
      let data = {
        date_from: "",
        date_to: "",
        search: "",
      };
      localStorage.setItem("AssetsListSearch", JSON.stringify(data));
    }
    if (
      pathname != "/feedback-history" &&
      !pathname.match("/feedback/details-feedback/")
    ) {
      let data = {
        emp_name: "",
        date: "",
        search: "",
      };
      let data2 = {
        search: null,
      };

      localStorage.setItem("FeedBackHistoryListSearch", JSON.stringify(data));
      localStorage.setItem("FeedBackHistoryListSearch2", JSON.stringify(data2));
    }
    if (
      pathname != "/feedback-related" &&
      !pathname.match("/feedback/details-feedback/")
    ) {
      let data = {
        date: "",
        search: "",
      };
      localStorage.setItem("FeedBackRelatedListSearch", JSON.stringify(data));
    }

    if (
      pathname != "/attendance" &&
      !pathname.match("/attendance/edit-attendance/")
    ) {
      let search = {
        selectedMember: null,
        filterName: moment().add(-1, "days").format("YYYY-MM-DD"),
      };
      localStorage.setItem("AttandanceListSearch", JSON.stringify(search));
    }
  }, [pathname]);

  return (
    <ThemeProvider>
      <ScrollToTop />
      <SnackbarProvider
        // hideIconVariant
        ref={notiStackRef}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        TransitionComponent={Slide}
        maxSnack={3}
        autoHideDuration={3000}
        action={(key) => (
          <IconButton onClick={onClickDismiss(key)}>
            <CloseIcon htmlColor="white" />
          </IconButton>
        )}
      >
        <ContextSocket>
          <Router />
        </ContextSocket>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
