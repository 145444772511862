import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
  useNavigate,
} from "react-router-dom";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Icon,
  Badge,
  Typography,
  TextField,
} from "@mui/material";
//
import Iconify from "./Iconify";
import { Team } from "src/doneSrc/pages";

const arrowIosDownwardFill = "eva:arrow-ios-downward-fill";
const arrowIosForwardFill = "eva:arrow-ios-forward-fill";
// ----------------------------------------------------------------------
const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  // fontSize: 16,
  width: "90%",
  margin: "auto",
  ...theme.typography.body2,
  height: 50,
  position: "relative",
  textTransform: "capitalize",
  // marginLeft: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
  marginTop: theme.spacing(0.5),
  paddingLeft: theme.spacing(1),
  // paddingRight: theme.spacing(1.5),
  color: "black",
  borderRadius: 10,

  "&:hover": {
    color: "#ffff",
    // color: "#6dfc51",
    width: "95%",

    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
    backgroundColor: theme.palette.primary.main,
  },
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

// const ListItemIconStyle = styled(ListItemIcon)({
//   width: 22,
//   height: 22,
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// });
const ListItemIconStyle = styled("div")(({ theme }) => ({
  // fontWeight: 400,
  marginRight: 15,
  display: "flex",
  borderRadius: "30%",
  alignItems: "center",
  // width: theme.spacing(8),
  // height: theme.spacing(8),
  justifyContent: "center",
  // boxShadow: " rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;",
  boxShadow:
    "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",

  // marginBottom: theme.spacing(2),
}));
const ListItemBageStyle = styled(Badge)({
  width: 22,
  height: 22,
});
// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};
function NavItem({ item, active }) {
  // const [activeStyle, setActiveStyle] = useState(isActiveRoot);
  const theme = useTheme();
  let childrenIsActive = false;

  if (item?.children) {
    childrenIsActive = item.children.some((child) => active(child.path));
  }

  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children, badge } = item;
  const [open, setOpen] = useState(
    item?.children ? childrenIsActive : isActiveRoot
  );

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: "#fff",
    fontWeight: "bold",
    width: "90%",
    margin: "auto",
    // boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
    // borderRadius: 2,
    // borderTopLeftRadius: 20,
    // borderBottomRightRadius: 20,
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.sidebarSelectedOpacity
    ),

    // "&:before": { display: "block" },
  };

  const activeSubStyle = {
    // color: "text.primary",
    // fontWeight: "fontWeightMedium",
    // // "&:hover": { color: "#655FB1" },
    // "&:hover": { color: "#FFFF" },

    color: "#fff",
    fontWeight: "bold",
    width: "90%",
    margin: "auto",
    // boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
    // borderRadius: 2,
    // borderTopLeftRadius: 20,
    // borderBottomRightRadius: 20,
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.sidebarSelectedOpacity
    ),
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle
            sx={{
              width: 35,
              height: 35,
              color: isActiveRoot ? "#016699" : "#919090",
              bgcolor: "#ffff",
              // "&:hover": { color: "#ffff" },
            }}
          >
            {icon && icon}
          </ListItemIconStyle>
          {/* <ListItemText
            primaryTypographyProps={{ variant: "body1", fontWeight: "500" }}
            primary={title}
          /> */}
          <ListItemText
            primaryTypographyProps={{ variant: "body1", fontWeight: "500" }}
          >
            <div className="d-flex">
              {title}
              {badge && (
                <div class="rounded-circle bg-primary  ml-1 h-25  p-1"></div>
              )}
            </div>
          </ListItemText>

          {/* {info && info} */}
          <Box sx={{ width: 16, height: 16, ml: 1 }}>
            <Iconify icon={open ? arrowIosDownwardFill : arrowIosForwardFill} />
          </Box>
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path, count } = item;
              const isActiveSub = active(path);
              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                        marginLeft: 2,

                        transition: (theme) =>
                          theme.transitions.create("transform"),
                        ...(isActiveSub && {
                          transform: "scale(2)",
                          bgcolor: "text.disabled",
                          // "&:hover": { color: "#ffff" },
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <div className="d-flex">
                    {" "}
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "body1",
                        fontWeight: "500",
                      }}
                      primary={title}
                    />
                    {count > 0 && (
                      <ListItemText
                        primaryTypographyProps={{
                          variant: "body1",
                          fontWeight: "500",
                          color: "#ffb700",
                          marginLeft: 1,
                        }}
                        primary={`(${count})`}
                      />
                    )}
                  </div>
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle
        sx={{
          width: 35,
          height: 35,
          color: isActiveRoot
            ? (theam) => theme.palette.primary.main
            : "#919090",
          bgcolor: "#fff",
        }}
      >
        {icon}
      </ListItemIconStyle>

      {/* <ListItemBageStyle badgeContent={true} variant="dot" color="error"> */}
      {/* {console.log(badge, "ljkl")} */}
      {badge ? (
        <div className="d-flex">
          <ListItemText
            primaryTypographyProps={{ variant: "body1", fontWeight: "500" }}
            className="d-flex"
          >
            {title}
          </ListItemText>{" "}
          <div class="rounded-circle bg-primary  ml-1 h-25  p-1"></div>
        </div>
      ) : (
        <ListItemText
          primaryTypographyProps={{ variant: "body1", fontWeight: "500" }}
          primary={title}
        />
      )}

      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.func,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();

  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  // =============good one is that but if thay ask then i show that because it will just mached chid not all childes
  // const findItemsByQuery = (query) => {
  //   const matchingItems = navConfig()
  //     .map((item) => {
  //       const matchedChildren = item?.children
  //         ? item?.children.filter((child) =>
  //             child.title.toLowerCase().includes(query.toLowerCase())
  //           )
  //         : "";
  //       if (
  //         item?.title.toLowerCase().includes(query.toLowerCase()) ||
  //         matchedChildren.length > 0
  //       ) {
  //         return {
  //           ...item,
  //           children: matchedChildren,
  //         };
  //       }
  //       return null;
  //     })
  //     .filter(Boolean);

  //   return matchingItems;
  // };

  // =======
  const findItemsByQuery = (query) => {
    const matchingItems = navConfig().filter(
      (item) =>
        item.title.toLowerCase().includes(query.toLowerCase()) ||
        (item.children &&
          item.children.some((child) =>
            child.title.toLowerCase().includes(query.toLowerCase())
          ))
    );
    return matchingItems;
  };

  const [query, setQuery] = useState("");
  const matchingItems = findItemsByQuery(query);
  // console.log("Matching items:", matchingItems);

  return (
    <Box {...other}>
      <div className="text-center">
        <TextField
          size="small"
          id="outlined-basic"
          label=""
          variant="outlined"
          autoComplete="off"
          sx={{
            width: "85%",

            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
            mb: 1,
          }}
          // fullWidth
          required
          name="question_statement"
          placeholder="Search..."
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
      </div>

      <List
        disablePadding
        // sx={{ p: 1, pt: 0.4 }}
        sx={{ mb: 6 }}
      >
        {matchingItems.map((item) => (
          // console.log(item, "djfadlka"),
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}
