import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Box,
  Card,
  Typography,
  Stack,
  Tooltip,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  IconButton,
  ButtonBase,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { baseUri, s3baseUrl } from 'src/doneSrc/config/config';
import MenuPopover from './MenuPopover';
import Iconify from './Iconify';
import { useAppContext } from 'src/hooks';
import Label from './Label';

// ----------------------------------------------------------------------

const ProjectImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});
const MenuContainer = styled('div')({
  top: 0,
  right: 0,
  position: 'absolute',
  zIndex: 2,
});

// ----------------------------------------------------------------------

WorkspaceCard.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }),
  onClickMenuItem: PropTypes.func.isRequired,
};

export default function WorkspaceCard({ item, menuOptions, onClickMenuItem }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(null);
  const { dispatch_set_current_selected_item } = useAppContext();
  // ------------------------------------------------------
  const handleClose = () => {
    setOpen(null);
  };

  const handleClickItem = (option, index) => {
    onClickMenuItem(option, index);
    handleClose();
  };
  const handleOpenOptions = (e, item) => {
    console.log(item, 'item');
    setOpen(e.currentTarget);
    dispatch_set_current_selected_item(item);
  };
  const handleNav = () => {
    navigate(`/workspaces/${item._id}`, { state: { workspace_data: item } });
  };

  // ------------------------------------------------------
  return (
    <>
      <Card sx={{ height: '100%' }}>
        <MenuContainer id='menu-container'>
          <IconButton
            // sx={{ bgcolor: 'rgba(255, 255, 255, 0.438)' }}
            ref={anchorRef}
            onClick={(e) => handleOpenOptions(e, item)}
          >
            <Iconify
              sx={{ color: 'black' }}
              // icon='mi:options-horizontal'
              icon='fluent:more-vertical-16-filled'
            />
          </IconButton>
          <MenuPopover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleClose}
            sx={{
              p: 0.5,
              mt: 1.5,
              ml: 0.75,
              width: '7rem',
              '& .MuiMenuItem-root': {
                typography: 'body2',
                borderRadius: 0.75,
              },
            }}
          >
            {menuOptions.map((option, index) => {
              return (
                <MenuItem
                  onClick={() => handleClickItem(option, index)}
                  key={index}
                  sx={{ width: '6.5rem' }}
                >
                  {option.label}
                </MenuItem>
              );
            })}
          </MenuPopover>
        </MenuContainer>
        <Box sx={{ pt: '100%', position: 'relative' }}>
          {item.image ? (
            <ProjectImgStyle
              alt={'name'}
              style={{ cursor: 'pointer' }}
              src={s3baseUrl + item.image}
              className='image-hov'
              onClick={handleNav}
            />
          ) : (
            <ProjectImgStyle
              alt={'name'}
              style={{ cursor: 'pointer' }}
              src='https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg'
              className='image-hov'
              onClick={handleNav}
            />
          )}
        </Box>
        <Tooltip title='Workspaces'>
          <Stack spacing={1} sx={{ p: 2, pt: 3.5 }}>
            <Typography variant='body1' noWrap onClick={() => null}>
              {item.name}
            </Typography>
            <Stack direction='row' alignItems='center' spacing={1} justifyContent='start'>
              <Typography
                variant='body2'
                // noWrap
                fontWeight='bold'
                onClick={() => null}
                component='span'
              >
                Projects
              </Typography>
              <Typography variant='body2' noWrap onClick={() => null} component='span'>
                {item.project}
              </Typography>
            </Stack>
            <Stack direction='row' alignItems='center' spacing={1} justifyContent='start'>
              <Typography
                variant='body2'
                // noWrap
                fontWeight='bold'
                onClick={() => null}
                component='span'
              >
                Members
              </Typography>
              <Typography variant='body2' noWrap onClick={() => null} component='span'>
                {item.member}
              </Typography>
            </Stack>
            {item.user?.name && (
              <Stack direction='row' alignItems='center' spacing={1} justifyContent='start'>
                <Typography
                  variant='body2'
                  // noWrap
                  fontWeight='bold'
                  onClick={() => null}
                  component='span'
                >
                  Admin
                </Typography>
                <Typography variant='body2' noWrap onClick={() => null} component='span'>
                  {item.user?.name}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Tooltip>
        <Tooltip title='Your Role'>
          <div className='role-bar'>
            <Label color='success' variant='ghost'>
              {item.role ? item.role : 'undefined'}
            </Label>
          </div>
        </Tooltip>
      </Card>
    </>
  );
}
