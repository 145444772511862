import { useState } from 'react';
// material
import { styled, alpha } from '@mui/material/styles';
import { Input, Slide, Button, IconButton, InputAdornment, ClickAwayListener } from '@mui/material';
import PropTypes from 'prop-types';
// components
import Iconify from './Iconify';

// ----------------------------------------------------------------------

const APPBAR_DESKTOP = '7.5vh';
const APPBAR_MOBILE = '7.5vh';

const SearchbarStyle = styled('div')(({ theme }) => ({
  top: '-4rem',
  left: 0,
  zIndex: theme.zIndex.appBar + 1,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    // height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));
const SearchbarContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '-3rem',
  width: '100%',
  zIndex: theme.zIndex.appBar + 6,
  [theme.breakpoints.only('sm')]: {
    left: '4rem',
    top: '-3.3rem',
  },
  [theme.breakpoints.only('xs')]: {
    left: '4rem',
    top: '-3.3rem',
  },
  [theme.breakpoints.only('md')]: {
    left: '4rem',
    top: '-3.3rem',
  },
}));

// ----------------------------------------------------------------------
StyledSearch.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  toggleShow: PropTypes.func.isRequired,
};
export default function StyledSearch({ value, onChange, open, toggleShow }) {
  return (
    <ClickAwayListener onClickAway={() => toggleShow(false)}>
      <div>
        <SearchbarContainer>
          {!open && (
            <IconButton onClick={() => toggleShow(true)}>
              <Iconify icon='eva:search-fill' width={20} height={20} />
            </IconButton>
          )}
        </SearchbarContainer>

        <Slide direction='down' in={open} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder='Search…'
              value={value}
              onChange={(e) => onChange(e.target.value)}
              startAdornment={
                <InputAdornment position='start'>
                  <Iconify
                    icon='eva:search-fill'
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            {/* <Button variant='contained'>Search</Button> */}
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
