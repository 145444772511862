import { invokeApi } from "src/utils";

export const _add_support_ticket_comment = async (_id, data) => {
  const requestObj = {
    path: `api/support_ticket/add_message_support_ticket/${_id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const editMySupport = async (_id, data) => {
  const requestObj = {
    path: `api/support_ticket/edit_support_ticket/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_support_ticket_comment = async (_id, data) => {
  const requestObj = {
    path: `api/support_ticket/delete_message_support_ticket/${_id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _support_ticket_details = async (_id) => {
  const requestObj = {
    path: `api/support_ticket/detail_support_ticket/${_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
