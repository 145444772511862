// ----------------------------------------------------------------------

export default function TaxDataRender({
  monthlySalary,
  annualTax,
  exemptSalary = 0,
  dataShow = "",
}) {
  let _monthlySalary = monthlySalary - exemptSalary;
  let _monthlyTax = (annualTax / 12).toFixed(2);
  let _IncomeAfterTax = (_monthlySalary - annualTax / 12).toFixed(2);
  let _YearlyIncome = (_monthlySalary * 12).toFixed(2);
  let _YearlyTax = annualTax.toFixed(2);
  let _YearlyIncomeAfterTax = ((_monthlySalary - annualTax / 12) * 12).toFixed(
    2
  );

  console.log("_YearlyTax", _YearlyTax);
  console.log("_YearlyTax", annualTax);

  function formatCurrency(amount) {
    let _amount = parseFloat(amount);
    if (!_amount) {
      _amount = 0;
    }
    return (
      _amount.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) + " PKR"
    );
  }

  return (
    <>
      {dataShow == "month" ? (
        <>
          <div className="d-flex justify-content-between">
            <span className="calculator-text">Monthly Income:</span>
            <span className="calculator-number">
              {formatCurrency(_monthlySalary)}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="calculator-text">Monthly Tax:</span>
            <span className="calculator-number">
              {formatCurrency(_monthlyTax)}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="calculator-text">Monthly Income After Tax:</span>
            <span className="calculator-number">
              {formatCurrency(_IncomeAfterTax)}
            </span>
          </div>
        </>
      ) : null}

      {dataShow == "year" ? (
        <>
          <div className="d-flex justify-content-between">
            <span className="calculator-text">Yearly Income:</span>
            <span className="calculator-number">
              {formatCurrency(_YearlyIncome)}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="calculator-text">Yearly Tax:</span>
            <span className="calculator-number">
              {formatCurrency(_YearlyTax)}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="calculator-text">Yearly Income After Tax:</span>
            <span className="calculator-number">
              {formatCurrency(_YearlyIncomeAfterTax)}
            </span>
          </div>
        </>
      ) : null}
    </>
  );
}
