import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "src/hooks";

export default function CheckPrivilege({ module, variable }) {
  const { _get_user_profile } = useAppContext();
  const navigate = useNavigate();
  const { privileges } = _get_user_profile();

  // console.log("privileges  ____privileges", privileges);
  if (!privileges || !privileges[module] || !privileges[module][variable]) {
    navigate(`/dashboard`);
  }
  return <></>;
}
