import { invokeApi } from "src/utils";
export const getLeavesAdmin = async (page, rows, filterdate, data) => {
  const requestObj = {
    path: `api/leave_request/search_leave_request_admin?search=${filterdate}&page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getLeavesLead = async (page, rows, data) => {
  const requestObj = {
    path: `api/leave_request/get_team_leave_requests_for_lead?page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addLeaveAdmin = async (data) => {
  const requestObj = {
    path: `api/leave_request/add_leave_request_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getLeaveDetail = async (id) => {
  const requestObj = {
    path: `api/leave_request/detail_leave_request/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const editLeaveAdmin = async (_id, data) => {
  const requestObj = {
    path: `api/leave_request/edit_leave_request_admin/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteLeaveAdmin = async (_id) => {
  const requestObj = {
    path: `api/leave_request/delete_leave_request_admin/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const editLeaveAcception = async (_id, data) => {
  const requestObj = {
    path: `api/leave_request/process_leave_request_admin/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getAllLeavesAdmin = async (page, rows, filterdate, data) => {
  const requestObj = {
    path: `api/leave_request/search_all_leave_request_admin?search=${filterdate}&page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_employee_leaves_for_report = async (page, rows, data) => {
  const requestObj = {
    path: `api/attendance/get_employee_leaves_for_report?&page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const getMembersLeaves = async (page, rows, search) => {
  const requestObj = {
    path: `api/leave_request/get_all_employee_leave_request_stats?search=${search}&page=${page}&limit=${rows}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _getMembersLeaves = async (page, rows, data) => {
  const requestObj = {
    path: `api/leave_request/get_all_employee_leave_request_stats?page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
