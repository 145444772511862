import { get_from_localStorage, invokeApi } from 'src/doneSrc/utils';

export const _current_team_list = async (data) => {
  const requestObj = {
    path: `api/workspace/workspace_team_list`,
    method: 'GET',
    headers: {
      'x-sh-auth': get_from_localStorage('token'),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _search_users = async (data, param) => {
  const requestObj = {
    path: `api/customer/search_customer?text=${param}`,
    method: 'GET',
    headers: {
      'x-sh-auth': get_from_localStorage('token'),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _remove_user_from_workspace = async (data) => {
  const requestObj = {
    path: `api/workspace/remove_user`,
    method: 'POST',
    headers: {
      'x-sh-auth': get_from_localStorage('token'),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
