import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
} from "@mui/material";
import { logo } from "src/assets";

// hooks
import useResponsive from "../../hooks/useResponsive";
import { useAppContext } from "src/hooks";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
//
import navConfig from "./NavConfig";
import NavSelector from "./NavConfig";
import { getTeamMemberDetail } from "src/DAL/teamMembers";
import { useSnackbar } from "notistack";
import { useSockets } from "src/Context/Context-Sockets/ContextSockets";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 250;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));
const LogoutContainer = styled("div")(({ theme }) => ({
  paddingInline: theme.spacing(2.5),
  paddingBlock: theme.spacing(1),
  marginTop: 15,
  backgroundColor: "#f3f7f6",
  position: "absolute",
  width: "100%",
  bottom: "0",
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { socketOFF } = useSockets();

  const { _set_user_profile } = useAppContext();
  const { _get_user_profile } = useAppContext();
  const isDesktop = useResponsive("up", "lg");
  const profile = _get_user_profile();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login", { replace: true });
    socketOFF();
  };
  const getData = async () => {
    try {
      const result = await getTeamMemberDetail(profile?.id);
      if (result.code == 200) {
        console.log(result, "hkih");
        localStorage.setItem(
          "previllages",
          JSON.stringify(result?.employee?.previllages)
        );
        localStorage.setItem("user", JSON.stringify(result?.employee));
        _set_user_profile((values) => ({
          ...values,
          role: result?.employee?.role,
          privileges: result?.employee?.previllages,
          pending_leaves: result?.employee?.pending_leaves,
          general_settings: result?.general_settings,
        }));

        console.log("result.general_settings hkih", result?.general_settings);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      console.error();
    } finally {
    }
  };
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);
  useEffect(() => {
    if (profile?.type == 1) {
      // console.log(profile, "kjdkjas");
      getData();
    }
  }, [profile?.type]);
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          // px: 2.5,
          pt: 4,
          pb: 2.5,
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <Logo /> */}
        {/* <Avatar
          src={logo}
          variant="square"
          sx={{
            width: 200,
            height: 60,
          }}
          alt="photoURL"
        /> */}
        <img
          loading="lazy"
          src={logo}
          width="70px"
          height="80px"
          alt="logo"
          // style={{ borderRadius: "50px" }}
        />
      </Box>

      {/* <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline='none' component={RouterLink} to='/profile'>
          <AccountStyle>
            {profile.image ? (
              <>
                <Avatar src={profile.image} alt='photoURL' />
              </>
            ) : (
              <>
                <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main }}>
                  {profile.first_name.charAt(0)}
                </Avatar>
              </>
            )}
            <Box sx={{ ml: 2 }}>
              <Typography variant='subtitle2' sx={{ color: 'text.primary' }}>
                {profile.first_name}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box> */}

      <NavSection navConfig={navConfig} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
          <LogoutContainer>
            <Button onClick={handleLogout} variant="contained" fullWidth>
              Logout
            </Button>
          </LogoutContainer>
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "none",
              backgroundColor: "#fafcfb",
              boxShadow: "rgba(0, 0, 0, 0.02) 0px 3px 5px;",
              zIndex: 0,
              // padding: 1,
            },
          }}
        >
          {renderContent}
          <LogoutContainer>
            <Button onClick={handleLogout} variant="contained" fullWidth>
              Logout
            </Button>
          </LogoutContainer>
        </Drawer>
      )}
    </RootStyle>
  );
}
