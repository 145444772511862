import PropTypes from "prop-types";
// material
import { Paper, Typography } from "@mui/material";
import { resultNotFound } from "src/assets";

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
};

export default function SearchNotFound({ searchQuery = "", ...other }) {
  return (
    <div className="align-center">
      <img
        // className="namalum"
        loading="lazy"
        src={resultNotFound}
        height={300}
        width={300}
        // style={{ borderRadius: "50px" }}
      />
    </div>
    // <Paper {...other}>
    //   <Typography gutterBottom align="center" variant="subtitle1">
    //     Not found
    //   </Typography>
    //   <Typography variant="body2" align="center">
    //     No results found for &nbsp;
    //     <strong>&quot;{searchQuery}&quot;</strong>. Try checking for typos or using complete words.
    //   </Typography>
    // </Paper>
  );
}
