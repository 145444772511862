import { Card, Container, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Page } from "src/components";
import TaxDataRender from "./components/TaxDataRender";
import CheckPrivilege from "src/components/generalComponents/CheckPrivilege";

function Calculator() {
  const [monthlySalary, setMonthlySalary] = useState("");
  const [exemptSalary, setExemptSalary] = useState("");
  const [annualTax, setAnnualTax] = useState(null);

  const calculateTax = (annualIncome) => {
    let tax = 0;

    if (annualIncome <= 600000) {
      tax = 0;
    } else if (annualIncome <= 1200000) {
      tax = 0.05 * (annualIncome - 600000);
    } else if (annualIncome <= 2200000) {
      tax = 30000 + 0.15 * (annualIncome - 1200000);
    } else if (annualIncome <= 3200000) {
      tax = 180000 + 0.25 * (annualIncome - 2200000);
    } else if (annualIncome <= 4100000) {
      tax = 430000 + 0.3 * (annualIncome - 3200000);
    } else {
      tax = 700000 + 0.35 * (annualIncome - 4100000);
    }

    return tax;
  };

  const handleCalculateTax = () => {
    const _exemptSalary = exemptSalary ? exemptSalary : 0;
    const annualIncome = (monthlySalary - _exemptSalary) * 12;
    const tax = calculateTax(annualIncome);
    const roundedAmount = Math.ceil(tax);
    setAnnualTax(roundedAmount);
  };

  useEffect(() => {
    handleCalculateTax();
  }, [monthlySalary, exemptSalary]);

  return (
    <>
      <CheckPrivilege module="tax_calculator" variable="view" />

      <Page title="Income Tax Calculator">
        <Container maxWidth="xl mt-0">
          <Card
            sx={{
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              mb: 1,
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              margin={2}
            >
              <Typography variant="h5" sx={{ fontWeight: "500" }}>
                Income Tax Calculator
              </Typography>
            </Stack>

            <Stack
              direction={{ xs: "column", md: "row" }}
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              margin={2}
            >
              <TextField
                type="text"
                value={monthlySalary}
                placeholder="Enter your monthly salary"
                label="Monthly Salary"
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={(e) => {
                  const regex = /^[0-9\b]+$/;
                  if (e.target.value === "" || regex.test(e.target.value)) {
                    setMonthlySalary(e.target.value >= 0 ? e.target.value : 0);
                  }
                }}
              />
              <TextField
                type="text"
                value={exemptSalary}
                placeholder="Enter your exempt monthly salary"
                label="Exempt Monthly Salary"
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={(e) => {
                  const regex = /^[0-9\b]+$/;
                  if (e.target.value === "" || regex.test(e.target.value)) {
                    setExemptSalary(e.target.value >= 0 ? e.target.value : 0);
                  }
                }}
              />
            </Stack>

            <div className="container px-4 mb-3">
              <div className="row">
                <div className="col-12 col-md-6">
                  {annualTax !== null && (
                    <TaxDataRender
                      monthlySalary={monthlySalary}
                      annualTax={annualTax}
                      exemptSalary={exemptSalary}
                      dataShow="month"
                    />
                  )}
                </div>

                <div className="col-12 col-md-6">
                  {annualTax !== null && (
                    <TaxDataRender
                      monthlySalary={monthlySalary}
                      annualTax={annualTax}
                      exemptSalary={exemptSalary}
                      dataShow="year"
                    />
                  )}
                </div>
              </div>
            </div>
          </Card>
        </Container>
      </Page>
    </>
  );
}

export default Calculator;
