import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import {
  Box,
  Card,
  Typography,
  Stack,
  Tooltip,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  IconButton,
  ButtonBase,
  AvatarGroup,
  Avatar,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { baseUri, s3baseUrl } from 'src/doneSrc/config/config';
import { useAppContext } from 'src/hooks';
import { MenuPopover, Iconify, Label, UserAvatar } from 'src/doneSrc/components';
import { get_zone_date } from 'src/doneSrc/utils';

// ----------------------------------------------------------------------

const ProjectImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});
const MenuContainer = styled('div')({
  top: 0,
  right: 0,
  position: 'absolute',
  zIndex: 2,
});

// ----------------------------------------------------------------------

ProjectCard.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
  }),
  onClickMenuItem: PropTypes.func.isRequired,
};

export default function ProjectCard({ item, menuOptions, onClickMenuItem, onClickTeam }) {
  const { completed_task, role, creator, description, end_date, image, _id } = item;
  const { pending_task, start_date, status, team, title, total_task, workspace } = item;
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch_set_current_selected_item } = useAppContext();
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(null);
  // ------------------------------------------------------
  const handleClose = () => {
    setOpen(null);
  };
  const showMenu = () => {
    if (role === 'admin') {
      return true;
    }
    if (role === 'manager') {
      return true;
    }
    return false;
  };

  const handleClickItem = (option, index) => {
    onClickMenuItem(option, index);
    handleClose();
  };
  const handleOpenOptions = (e, item) => {
    console.log(item);
    setOpen(e.currentTarget);
    dispatch_set_current_selected_item(item);
  };
  const handleNav = () => {
    navigate(`/workspaces/projects/${item._id}`);
  };
  const getProgress = () => {
    let progress = (Number(completed_task) / Number(total_task)) * 100;
    let percentage = `${Math.round(progress)}%`;
    if (Number(completed_task) === 0) {
      return '0%';
    }
    return percentage;
  };
  const handleTeamClick = () => {
    console.log(item);
    dispatch_set_current_selected_item({
      project: item,
    });
    onClickTeam();
  };
  // ------------------------------------------------------
  return (
    <>
      <Card sx={{ height: '100%' }}>
        {showMenu() && (
          <MenuContainer id='menu-container'>
            <IconButton
              // sx={{ bgcolor: 'rgba(255, 255, 255, 0.438)' }}
              ref={anchorRef}
              onClick={(e) => handleOpenOptions(e, item)}
            >
              <Iconify
                sx={{ color: 'black' }}
                // icon='mi:options-horizontal'
                icon='fluent:more-vertical-16-filled'
              />
            </IconButton>
            <MenuPopover
              open={Boolean(open)}
              anchorEl={open}
              onClose={handleClose}
              sx={{
                p: 0.5,
                mt: 1.5,
                ml: 0.75,
                width: '7rem',
                '& .MuiMenuItem-root': {
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              }}
            >
              {menuOptions.map((option, index) => {
                return (
                  <MenuItem
                    onClick={() => handleClickItem(option, index)}
                    key={index}
                    sx={{ width: '6.5rem' }}
                  >
                    {option.label}
                  </MenuItem>
                );
              })}
            </MenuPopover>
          </MenuContainer>
        )}
        <Box sx={{ pt: '100%', position: 'relative' }}>
          {image ? (
            <ProjectImgStyle
              alt={'name'}
              style={{ cursor: 'pointer' }}
              src={s3baseUrl + image}
              className='image-hov'
              onClick={handleNav}
            />
          ) : (
            <ProjectImgStyle
              alt={'name'}
              style={{ cursor: 'pointer' }}
              src='https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg'
              className='image-hov'
              onClick={handleNav}
            />
          )}
        </Box>

        <Stack spacing={1} sx={{ p: 2, pt: 3 }}>
          <Typography variant='body1' noWrap onClick={() => null}>
            {title}
          </Typography>
          <Stack direction='row' alignItems='center' spacing={1} justifyContent='start'>
            <Typography
              variant='body2'
              // noWrap
              fontWeight='bold'
              onClick={() => null}
              component='span'
            >
              End Date
            </Typography>
            <Typography variant='body2' noWrap onClick={() => null} component='span'>
              {get_zone_date(end_date, 'DD MMM YYYY')}
            </Typography>
          </Stack>
          <Stack direction='row' alignItems='center' spacing={1} justifyContent='start'>
            <Typography
              variant='body2'
              // noWrap
              fontWeight='bold'
              onClick={() => null}
              component='span'
            >
              Tasks Completed
            </Typography>
            <Typography variant='body2' noWrap onClick={() => null} component='span'>
              {completed_task}/{total_task}
            </Typography>
          </Stack>
          <Stack direction='row' alignItems='center' spacing={1} justifyContent='start'>
            <Typography
              variant='body2'
              // noWrap
              fontWeight='bold'
              onClick={() => null}
              component='span'
            >
              Progress
            </Typography>
            <Typography variant='body2' noWrap component='span'>
              {getProgress()}
            </Typography>
          </Stack>
          {team.length !== 0 && (
            <Stack justifyContent='flex-end' direction='row' alignItems='center' spacing={1}>
              <Tooltip title='Project team'>
                <div className='avatar-container'>
                  <AvatarGroup onClick={handleTeamClick} sx={{ height: 33, width: 33 }} max={3}>
                    {team.map((user) => {
                      const { first_name, last_name, email, image, status } = user.user_profile;
                      return (
                        <UserAvatar
                          key={user._id}
                          name={first_name}
                          image={image}
                          alt={first_name}
                        />
                      );
                    })}
                  </AvatarGroup>
                </div>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </Card>
    </>
  );
}
