// here we add all base urls and keys

// Base URLS
// export const baseUri = "http://192.168.1.5:4622/";
export const baseUri = "https://checklistnodeapi.dynamitedigital.info/";
export const s3baseUrl = "https://s3.amazonaws.com/d.one-app-bucket/";
export const socketUrl = "";

// Keys
// export const secret_key = process.env.REACT_APP_API_SECRET_KEY;
