import {
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@mui/material";
import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useTransition,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  MuiAutoComplete,
  MuiDrawer,
  MuiSelect,
  NavBackHeader,
  Page,
} from "src/doneSrc/components";
import { debounce } from "lodash";
import {
  _invite_user_to_workspace,
  _search_users,
  _user_workspaces,
} from "src/doneSrc/DAL";
import TeamUserCard from "./components/TeamUserCard";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { get_from_localStorage } from "src/doneSrc/utils";
import { useAppContext } from "src/hooks";
// ----------------------------------------------------
/* DEBOUNCE SEARCH */
const fetchData = async (query, cb) => {
  const res = await _search_users(undefined, query);
  cb(res);
};
const debouncedFetchData = debounce((query, cb) => {
  fetchData(query, cb);
}, 200);
// ----------------------------------------------------
const ROLE_OPTIONS = [
  {
    label: "Manager",
    value: "manager",
  },
  {
    label: "Team",
    value: "team",
  },
];
const AddUser = () => {
  const navigate = useNavigate();
  const [isPending, startTransition] = useTransition();
  const { dispatch_get_search_value, dispatch_set_search_value } =
    useAppContext();
  const [searchValue, setSearchValue] = useState(dispatch_get_search_value());
  const [roleValue, setRoleValue] = useState("");
  const [dragValue, setDragValue] = useState("1");
  const [addProjValue, setAddProjValue] = useState("1");
  const [loading, setLoading] = useState(false);
  const [inviteDrawerOpen, setInviteDrawerOpen] = useState(false);
  const [sendingInv, setSendingInv] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [userList, setUserList] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleAutocompleteChange = (value) => {
    console.log(value, "val");
    setAutoCompleteValue(value);
  };
  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };
  const handleChangeDrag = (e) => {
    setDragValue(e.target.value);
  };
  const handleChangeAddProj = (e) => {
    setAddProjValue(e.target.value);
  };
  const onChangeRole = (e) => {
    setRoleValue(e.target.value);
  };
  const onInviteUser = (_data) => {
    console.log(_data);
    setSelectedUser(_data);
    setInviteDrawerOpen(true);
    getWorkSpaces();
  };
  const closeInvDrawer = (_data) => {
    setAutoCompleteValue(null);
    setRoleValue("");
    setInviteDrawerOpen(false);
    setDragValue("1");
    setAddProjValue("1");
  };
  const getText = () => {
    const user_count = userList.length;
    let not_found = `No Results found for "${searchValue}"`;
    if (searchValue === "") {
      if (!loading) {
        if (userList.length === 0) {
          return "Start Searching Users";
        }
        if (userList.length > 0) {
          return `Found ${user_count} ${user_count === 1 ? "User" : "Users"}`;
        }
      }
      if (loading) {
        return "Searching Users...";
      }
    }
    if (searchValue !== "") {
      if (!loading) {
        if (userList.length === 0) {
          return not_found;
        }
        if (userList.length > 0) {
          return `Found ${user_count} ${user_count === 1 ? "User" : "Users"}`;
        }
      }
      if (loading) {
        return "Searching Users...";
      }
    }
  };
  const onRoleOptionClick = (e, _option) => {
    console.log(_option);
    // setRoleValue(e.target.value);
  };
  const getWorkSpaces = async () => {
    const result = await _user_workspaces();
    const getPersonal = (_data) => {
      let data = _data.map((item) => {
        return {
          ...item,
          ["role"]: "admin",
          ["workspace_type"]: "mine",
          ["label"]: item.name,
          ["value"]: item,
        };
      });
      return data;
    };
    if (result.code === 200) {
      // console.log(result, "workspace-res");
      const with_manager_role = result.workspace.share
        .filter((item) => String(item.role) === "manager")
        .map((_item) => {
          return { ..._item, ["label"]: _item.name, ["value"]: _item };
        });
      setWorkspaces([
        ...getPersonal(result.workspace.personal),
        ...with_manager_role,
      ]);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleSendInvite = async () => {
    if (!autoCompleteValue) {
      enqueueSnackbar("Please Choose a Workspace", { variant: "error" });
      return;
    }
    if (!roleValue) {
      enqueueSnackbar("Please Choose a Role", { variant: "error" });
      return;
    }
    if (!selectedUser) {
      enqueueSnackbar("No user selected", { variant: "error" });
      return;
    }
    console.log(autoCompleteValue, "workspace");
    console.log(roleValue, "role");
    console.log(selectedUser, "selected-user");
    let drag_drop = true;
    let add_project = true;
    if (roleValue === "team") {
      if (dragValue === "0") {
        drag_drop = false;
      }
    }
    if (roleValue === "team") {
      if (addProjValue === "0") {
        add_project = false;
      }
    }
    const data = {
      workspace: autoCompleteValue._id,
      email: selectedUser.email,
      role: roleValue,
      token: "",
      allow_drag_drop: drag_drop,
      allow_add_project: add_project,
    };
    console.log(data, "inv-req");
    setSendingInv(true);
    const result = await _invite_user_to_workspace(data);
    if (result.code === 200) {
      console.log(result, "inv-res");
      setSendingInv(false);
      closeInvDrawer();
      enqueueSnackbar("Invitation Sent Successfully", { variant: "success" });
    } else {
      setSendingInv(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  // ----------------------------------------------------

  useLayoutEffect(() => {
    getWorkSpaces();
  }, []);

  React.useEffect(() => {
    // if (searchValue === "") {
    //   setUserList([]);
    // }
    // if (searchValue !== "") {
    setLoading(true);
    debouncedFetchData(searchValue, (res) => {
      setLoading(false);
      let modified = res.data.customer.map((item) => {
        return { ...item, is_invited: false };
      });
      setUserList(modified);
      console.log(res, "res");
    });
    // }
  }, [searchValue]);
  // React.useEffect(() => {
  //   if (searchValue === "") {
  //     if (userList.length !== 0) {
  //       setUserList([]);
  //     }
  //   }
  // });

  // ----------------------------------------------------
  return (
    <Page title="Add Team">
      <Container maxWidth="xl mt-1">
        <div className="mb-4">
          <NavBackHeader heading="Add Team" />
        </div>
        <Stack spacing={2.5}>
          <Grid alignItems="center" columnSpacing={1} container>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Search user"
                value={searchValue}
                onChange={handleChange}
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position='end'>
                //       <IconButton edge='end'>
                //         <CircularProgress
                //           sx={{
                //             display: `${loading ? 'block' : 'none'}`,
                //           }}
                //           size={'1.5rem'}
                //         />
                //       </IconButton>
                //     </InputAdornment>
                //   ),
                // }}
              />
            </Grid>
          </Grid>
          <div style={{ height: "1.5rem" }}>
            {getText() === "Searching Users..." ? (
              <div className="centered-row">
                <CircularProgress
                  sx={{
                    display: `${loading ? "block" : "none"}`,
                  }}
                  size={"1.5rem"}
                />
              </div>
            ) : (
              <Typography
                sx={{
                  color: getText().includes("No Results")
                    ? (theme) => theme.palette.text.disabled
                    : (theme) => theme.palette.text.primary,
                }}
                fontWeight="bold"
                textAlign="center"
                variant="body1"
                noWrap
              >
                {getText()}
              </Typography>
            )}
          </div>

          {/* {searchValue !== "" ? ( */}
          <Grid container>
            {userList.length > 0 &&
              userList.map((user, index) => (
                <Grid
                  sx={{ pr: 3, pb: 3 }}
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={3}
                >
                  <TeamUserCard onInvite={onInviteUser} item={user} />
                </Grid>
              ))}
          </Grid>
          {/* ) : (
            <Grid container>
              <></>
            </Grid>
          )} */}
        </Stack>
        {/* Drawers */}
        <MuiDrawer
          title="Invite User To Workspace"
          onClose={closeInvDrawer}
          isOpen={inviteDrawerOpen}
        >
          <Stack spacing={3}>
            <TextField
              variant="outlined"
              label="User Email"
              value={selectedUser?.email}
            />
            <MuiAutoComplete
              value={autoCompleteValue}
              onChange={handleAutocompleteChange}
              options={workspaces}
              label="Choose workspace"
            />
            <MuiSelect
              onOptionClick={onRoleOptionClick}
              options={ROLE_OPTIONS}
              value={roleValue}
              onChange={onChangeRole}
              label="Choose Role"
            />
            {roleValue === "team" && (
              <FormControl>
                <FormLabel>Drag-Drop</FormLabel>
                <RadioGroup
                  title="Drag-Drop"
                  row
                  disabled={sendingInv}
                  aria-labelledby="gender-select-radio-buttons-label"
                  name="row-radio-buttons-group"
                  value={dragValue}
                  onChange={handleChangeDrag}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Allow"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Disallow"
                  />
                </RadioGroup>
              </FormControl>
            )}
            {roleValue === "team" && (
              <FormControl>
                <FormLabel>Add project</FormLabel>
                <RadioGroup
                  title="Drag-Drop"
                  row
                  disabled={sendingInv}
                  aria-labelledby="gender-select-radio-buttons-label"
                  name="row-radio-buttons-group"
                  value={addProjValue}
                  onChange={handleChangeAddProj}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Allow"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="Disallow"
                  />
                </RadioGroup>
              </FormControl>
            )}
            <div className="centered-row">
              <LoadingButton
                loading={sendingInv}
                onClick={handleSendInvite}
                variant="contained"
              >
                Send Invite
              </LoadingButton>
            </div>
          </Stack>
        </MuiDrawer>
      </Container>
    </Page>
  );
};

export default AddUser;
