import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";

function NotificationConfirmation({
  openApprove,
  setOpenApprove,
  handleAgree,
  title,
  open,
  setOpen,
}) {
  const handleCloseDialog = () => {
    setOpenApprove(false);
  };
  const handleCloseDialog1 = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={openApprove ? openApprove : false}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle>
          {title ? title : "Are you sure you want to take this action?"}
        </DialogTitle>
        <DialogActions>
          <Button className="model-button-hover" onClick={handleAgree}>
            ok
          </Button>
        </DialogActions>
      </Dialog>
      {/* New to solve delete issue  */}
      <Dialog
        open={open ? open : false}
        onClose={handleCloseDialog1}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle>
          {title ? title : "Are you sure you want to take this action?"}
        </DialogTitle>
        <DialogActions>
          <Button className="model-button-hover" onClick={handleCloseDialog1}>
            Cancel
          </Button>
          <Button className="model-button-hover" onClick={handleAgree}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NotificationConfirmation;
