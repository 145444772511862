import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";

import {
  Typography,
  Container,
  Stack,
  Box,
  IconButton,
  Divider,
  DialogContentText,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PoperForPrivilegs from "./PoperForPrivilegs";
import Scrollbar from "./Scrollbar";
import { Icon } from "@iconify/react";
import moment from "moment";
import { getLeaveDetail } from "src/DAL/leaveAdmin";
function DetailsToShow({ openDetails, setOpenDetails, Data }) {
  const anchorRef = useRef(null);
  const [data, setData] = useState("");
  const handleOpen = () => {
    setOpenDetails(true);
  };
  const handleClose = () => {
    setData("");
    setOpenDetails(false);
  };

  // console.log(openDetails, "kjfkadiufa");

  async function getDetailsOfLeave() {
    try {
      const res = await getLeaveDetail(
        Data.leave_request_id ? Data.leave_request_id : Data._id
      );
      if (res.code === 200) {
        // console.log(res.leave_request, "getLeaveDetail");
        setData(res.leave_request);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    if (openDetails) {
      getDetailsOfLeave();
    }
  }, [openDetails]);
  return (
    <div>
      {data && (
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "90%",
              minHeight: 435,
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            },
          }}
          open={openDetails ? openDetails : false}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Container>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  // mb={1.5}
                >
                  <Typography variant="h5" sx={{ fontWeight: "500" }}>
                    {data?.emp_name ? data?.emp_name : "N/A"}
                  </Typography>
                  <IconButton
                    sx={{ width: 50, height: 50 }}
                    onClick={handleClose}
                  >
                    <Icon icon="emojione:cross-mark-button" />
                  </IconButton>
                </Stack>
                <Scrollbar sx={{ height: { xs: 340, sm: "auto", md: 340 } }}>
                  <Box
                    sx={{
                      width: "100%",
                      // display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      overflow: "hidden",
                    }}
                    marginTop={4}
                  >
                    <div class="p-0">
                      <div class="d-flex flex-row justify-content-start mb-1">
                        <div class="fixedWidth">
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold", fontSize: 14 }}
                          >
                            Leave Type
                          </Typography>
                        </div>
                        <div class="mx-auto">
                          <Typography variant="body1">
                            {data?.leave_type
                              ? data?.leave_type.charAt(0).toUpperCase() +
                                data?.leave_type.slice(1).replace(/_/g, " ")
                              : "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: "#595959",
                        marginBottom: 0.5,
                      }}
                    ></Divider>
                    <div class="p-0">
                      <div class="d-flex flex-row justify-content-start mb-1">
                        <div class="fixedWidth">
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold", fontSize: 14 }}
                          >
                            Leave Date
                          </Typography>
                        </div>
                        <div class="mx-auto">
                          <Typography variant="body1">
                            {data?.leave_date
                              ? moment(data?.leave_date).format("DD/MM/YYYY")
                              : "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: "#595959",
                        marginBottom: 0.5,
                      }}
                    ></Divider>
                    <div class="p-0">
                      <div class="d-flex flex-row justify-content-start mb-1">
                        <div class="fixedWidth">
                          <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
                            Leave Reason
                          </Typography>
                        </div>
                        <div class="mx-auto">
                          <div
                            className="set-text"
                            dangerouslySetInnerHTML={{
                              __html: data?.leave_reason
                                ? data?.leave_reason
                                : "N/A",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <Divider
                      sx={{
                        width: "100%",
                        borderColor: "#595959",
                        marginBottom: 0.5,
                      }}
                    ></Divider>
                    {data?.rejection_reason && (
                      <>
                        <div class="p-0">
                          <div class="d-flex flex-row justify-content-start mb-1">
                            <div class="fixedWidth">
                              <Typography
                                sx={{ fontWeight: "bold", fontSize: 14 }}
                              >
                                Reject Reason
                              </Typography>
                            </div>
                            <div class="mx-auto">
                              <Typography>{data?.rejection_reason}</Typography>
                            </div>
                          </div>
                        </div>
                        <Divider
                          sx={{
                            width: "100%",
                            borderColor: "#595959",
                            marginBottom: 0.5,
                          }}
                        ></Divider>
                      </>
                    )}
                  </Box>
                </Scrollbar>
              </Container>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </div>

    // <div
    //   style={{ cursor: "pointer", position: "relative" }}
    //   className=" remove-border menu-background-circle "
    // >
    //   <PoperForPrivilegs
    //     className="custom-popover-details"
    //     open={openDetails}
    //     onClose={handleClose}
    //     anchorEl={anchorRef}
    //     sx={{
    //       marginLeft: 1,
    //     }}
    //   >
    //     <Container>
    //       <Stack
    //         direction="row"
    //         alignItems="center"
    //         justifyContent="space-between"
    //         // mb={1.5}
    //       >
    //         <Typography variant="h5" sx={{ fontWeight: "500" }}>
    //           {data?.emp_name ? data?.emp_name : "N/A"}
    //         </Typography>
    //         <IconButton sx={{ width: 50, height: 50 }} onClick={handleClose}>
    //           <Icon icon="emojione:cross-mark-button" />
    //         </IconButton>
    //       </Stack>
    //       <Scrollbar sx={{ height: { xs: 340, sm: "auto", md: 340 } }}>
    //         <Box
    //           sx={{
    //             width: "100%",
    //             // display: "inline-flex",
    //             alignItems: "center",
    //             justifyContent: "center",
    //             overflow: "hidden",
    //           }}
    //           marginTop={4}
    //         >
    //           <div class="p-0">
    //             <div class="d-flex flex-row justify-content-start mb-1">
    //               <div class="fixedWidth">
    //                 <Typography
    //                   variant="body1"
    //                   sx={{ fontWeight: "bold", fontSize: 14 }}
    //                 >
    //                   Leave Date
    //                 </Typography>
    //               </div>
    //               <div class="mx-auto">
    //                 <Typography variant="body1">
    //                   {data?.leave_date
    //                     ? moment(data?.leave_date).format("DD/MM/YYYY")
    //                     : "N/A"}
    //                 </Typography>
    //               </div>
    //             </div>
    //           </div>
    //           <Divider
    //             sx={{
    //               width: "100%",
    //               borderColor: "#595959",
    //               marginBottom: 0.5,
    //             }}
    //           ></Divider>
    //           <div class="p-0">
    //             <div class="d-flex flex-row justify-content-start mb-1">
    //               <div class="fixedWidth">
    //                 <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
    //                   Leave Reason
    //                 </Typography>
    //               </div>
    //               <div class="mx-auto">
    //                 <div
    //                   className="set-text"
    //                   dangerouslySetInnerHTML={{
    //                     __html: data?.leave_reason ? data?.leave_reason : "N/A",
    //                   }}
    //                 />
    //               </div>
    //             </div>
    //           </div>
    //           <Divider
    //             sx={{
    //               width: "100%",
    //               borderColor: "#595959",
    //               marginBottom: 0.5,
    //             }}
    //           ></Divider>
    //           {data?.rejection_reason && (
    //             <>
    //               <div class="p-0">
    //                 <div class="d-flex flex-row justify-content-start mb-1">
    //                   <div class="fixedWidth">
    //                     <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
    //                       Reject Reason
    //                     </Typography>
    //                   </div>
    //                   <div class="mx-auto">
    //                     <Typography>{data?.rejection_reason}</Typography>
    //                   </div>
    //                 </div>
    //               </div>
    //               <Divider
    //                 sx={{
    //                   width: "100%",
    //                   borderColor: "#595959",
    //                   marginBottom: 0.5,
    //                 }}
    //               ></Divider>
    //             </>
    //           )}
    //         </Box>
    //       </Scrollbar>
    //     </Container>
    //   </PoperForPrivilegs>
    // </div>
  );
}

export default DetailsToShow;
