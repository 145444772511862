import { Snackbar } from "@material-ui/core";

import Alert from "@mui/material/Alert";

import React, { useContext, useEffect, useState } from "react";

import { baseUri, s3baseUrl } from "src/config/config";

import socketIOClient from "socket.io-client";

import { Bongo } from "src/assets";
import { teamNotification } from "src/DAL/notifications";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { _support_ticket_details } from "src/DAL/supportTicketMessages";
import { useAppContext } from "src/hooks";
const SocketContext = React.createContext();
export const useSockets = () => useContext(SocketContext);
let socket = socketIOClient();
export function ContextSocket({ children }) {
  const { _set_user_profile } = useAppContext();

  const [chatLeaveRequest, setChatLeaveRequest] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationsPart2, setNotificationsPart2] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [loading, setLoading] = useState(true);
  const [ticketData, setTicketData] = useState("");
  const [comments, setComments] = useState([]);
  const [delCmments, setDelComments] = useState([]);
  const [undereadMessages, setUndereadMessages] = useState();
  const [openBackdrop, setOpenBackdrop] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  /////////////////////////////////////////////////////////////////////////chat states
  const [snakbar, setSnakbar] = useState({
    show: false,
    message: "hi",
    type: "success",
    duration: 6000,
  });
  const [startSocket, setStartSocket] = useState(
    localStorage.getItem("token") ? true : false
  );

  ////////////////////////////////////////////////--->Start and stop socket

  const playNotification = () => {
    // let audio = new Audio("src/mp3/bo")
    const tune = new Audio(Bongo);
    tune.play();
  };

  const showInfoSnak = (msg) => {
    if (!msg) return;
    setSnakbar({
      show: true,
      message: msg,
      type: "info",
      duration: 2000,
    });
  };
  const handleClose = () => {
    setSnakbar({
      show: false,
      message: "",
      type: "",
      duration: 6000,
    });
  };
  const socketON = () => {
    setStartSocket(true);
  };

  const socketOFF = () => {
    setStartSocket(false);
    socket.disconnect();
    setSelectedUser("");
  };

  ////////////////////////////////////////////////--->chat handlers and state management
  const fetchMessagesInDetails = async (ticket_id) => {
    const resp = await _support_ticket_details(ticket_id);
    if (resp.code === 200) {
      // console.log(resp.support_ticket, "kjdfhkjsdh");
      setTicketData(resp.support_ticket);
      let name = resp?.support_ticket?.title;
      // console.log("userData", ticketData.subject);
      setComments(resp.support_ticket.messages);
      setOpenBackdrop(false);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
      setOpenBackdrop(false);
    }
  };
  const fetchNotificationListing = async (limit) => {
    // const formData = new FormData();
    setLoading(true);
    const result = await teamNotification(limit);
    if (result.code === 200) {
      // console.log("notificationnotificationnotificationnotification", result);
      setNotifications(result.notifications);
      setNotificationsPart2(result.notifications);

      setUnreadNotifications(result.total_unread_notifications);
      setLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setLoading(false);
    }
  };

  const handleDeleteMessage = (data) => {
    console.log(data, "notificationsasdnotificationsasd");
    setDelComments((values) => [...values, data]);
  };
  const handleNewNotifications = (data) => {
    // showInfoSnak(data.description);
    enqueueSnackbar(data.description, { variant: "info" });
    // console.log(data.sidebar_status, "notificationsasdnotificationsasd");
    if (data.sidebar_status) {
      // console.log("iininin");
      _set_user_profile((values) => ({
        ...values,
        ["sidebar_status"]: data?.sidebar_status,
      }));
    }
    let notification_list = notifications;
    notification_list.map((not) => {
      if (not._id == data._id) {
        return;
      }
    });
    setUnreadNotifications((prevValue) => prevValue + 1);
    data.unread_status = true;
    setNotifications((values) => [data, ...values]);
  };
  const handleNewMessage = (data) => {
    // showInfoSnak(data.description);

    // console.log(data, "notificationsasdnotificationsasd");

    setComments((values) => [...values, data]);

    // _set_user_profile((values) => ({
    //   ...values,

    //   privileges: result?.employee?.previllages,
    // }));
  };
  const handleMessageCount = (data) => {
    // console.log(data, "handleMessageCounthandleMessageCount");
    if (data.sidebar_status) {
      _set_user_profile((values) => ({
        ...values,
        ["sidebar_status"]: data?.sidebar_status,
      }));
    }
    setUndereadMessages(data);
    // setComments((values) => [...values, data]);
  };
  const handleLeavesCount = (data) => {
    // console.log(data, "handleMessageCounthandleMessageCount");

    _set_user_profile((values) => ({
      ...values,
      ["pending_leaves"]: data,
    }));

    // setComments((values) => [...values, data]);
  };
  const handleCloseTicket = (message) => {
    socketEmit("support_ticket_status_change", message);
  };
  const handleDeleteTicket = (message) => {
    socketEmit("support_ticket_message_delete", message);
  };
  const handleSendMessageLeaveRequest = (message) => {
    // console.log(message, "leave_request_sent");

    socketEmit("leave_request_sent", message);

    setChatLeaveRequest(!chatLeaveRequest);
  };
  const handleSendMessageFeedback = (message) => {
    // console.log(message, "leave_request_sent");

    socketEmit("feedback_sent", message);

    setChatLeaveRequest(!chatLeaveRequest);
  };
  const handleLunchConfirmation = (message) => {
    // console.log(message, "leave_request_sent");

    socketEmit("avail_lunch_sent", message);

    setChatLeaveRequest(!chatLeaveRequest);
  };
  const handleSendMessageLoanRequestProcess = (message) => {
    // console.log(message, "leave_request_reply_sent");

    socketEmit("loan_request_reply_sent", message);

    setChatLeaveRequest(!chatLeaveRequest);
  };
  const handleSendMessageLeaveRequestProcess = (message) => {
    // console.log(message, "leave_request_reply_sent");

    socketEmit("leave_request_reply_sent", message);

    setChatLeaveRequest(!chatLeaveRequest);
  };
  const handleSendMessageLunch = (message) => {
    // console.log(message, "leave_request_reply_sent");

    socketEmit("lunch_added_sent", message);

    setChatLeaveRequest(!chatLeaveRequest);
  };
  const handleSendMessageLoanRequest = (message) => {
    // console.log(message, "leave_request_reply_sent");

    socketEmit("loan_request_sent", message);

    setChatLeaveRequest(!chatLeaveRequest);
  };
  const handleSendMessageInSupportTicketDetails = (message) => {
    // console.log(message, "support_ticket_message_sent");

    socketEmit("support_ticket_message_sent", message);

    setChatLeaveRequest(!chatLeaveRequest);
  };
  const handleSendMessageSupportTicket = (message) => {
    // console.log(message, "support_ticket_sent");

    socketEmit("support_ticket_sent", message);

    setChatLeaveRequest(!chatLeaveRequest);
  };
  const handleSendMessageAnnouncement = (message) => {
    // console.log(message, "announcement_sent");

    socketEmit("announcement_sent", message);

    setChatLeaveRequest(!chatLeaveRequest);
  };
  useEffect(() => {
    if (startSocket) {
      connectSocket();
    }
  }, [startSocket]);

  const socketEmit = (name, data) => {
    socket.emit(name, data);
  };
  const socket_on_listner_list = [
    {
      listner_name: "connect",
      listner_handler: () => {
        console.log("connected TO Socket");
      },
    },
    {
      listner_name: "leave_request_pending_counts_receive",
      listner_handler: handleLeavesCount,
    },
    {
      listner_name: "loan_request_reply_receive",
      listner_handler: handleNewNotifications,
    },
    {
      listner_name: "loan_request_receive",
      listner_handler: handleNewNotifications,
    },
    {
      listner_name: "lunch_added_receive",
      listner_handler: handleNewNotifications,
    },
    {
      listner_name: "avail_lunch_receive",
      listner_handler: handleNewNotifications,
    },
    {
      listner_name: "announcement_receive",
      listner_handler: handleNewNotifications,
    },
    {
      listner_name: "feedback_receive",
      listner_handler: handleNewNotifications,
    },
    {
      listner_name: "leave_request_receive",
      listner_handler: handleNewNotifications,
    },
    {
      listner_name: "leave_request_reply_receive",
      listner_handler: handleNewNotifications,
    },
    {
      listner_name: "support_ticket_message_delete_receive",
      listner_handler: handleDeleteMessage,
    },
    {
      listner_name: "support_message_receive",
      listner_handler: handleNewNotifications,
    },

    {
      listner_name: "support_ticket_receive",
      listner_handler: handleNewNotifications,
    },
    {
      listner_name: "support_ticket_status_change_receive",
      listner_handler: handleNewNotifications,
    },
    {
      listner_name: "support_ticket_message_sent_receive",
      listner_handler: handleNewMessage,
    },
    {
      listner_name: "support_ticket_message_unread_count",
      listner_handler: handleMessageCount,
    },
  ];

  const connectSocket = () => {
    console.log("connectingSocket");
    const user_socket_id = JSON.parse(localStorage.getItem("user"))?.user_id
      ?._id;
    const ENDPOINT = `${baseUri}?roomId=${user_socket_id}`;
    socket = socketIOClient(ENDPOINT);
    socket_on_listner_list.map((listner) => {
      socket.on(`${listner.listner_name}`, listner.listner_handler);
    });
  };

  return (
    <SocketContext.Provider
      value={{
        unreadNotifications,
        notifications,
        loading,
        setUnreadNotifications,
        handleCloseTicket,
        handleSendMessageLeaveRequestProcess,
        handleSendMessageLoanRequestProcess,
        handleSendMessageSupportTicket,
        handleDeleteTicket,
        handleSendMessageAnnouncement,
        handleLunchConfirmation,
        handleSendMessageInSupportTicketDetails,
        setNotifications,
        handleSendMessageLunch,
        handleSendMessageLoanRequest,
        notificationsPart2,
        fetchNotificationListing,
        fetchMessagesInDetails,
        handleSendMessageLeaveRequest,
        handleSendMessageFeedback,
        socketON,
        socketOFF,
        ticketData,
        setTicketData,
        comments,
        setComments,
        undereadMessages,
        openBackdrop,
        delCmments,
      }}
    >
      {children}
      <Snackbar
        open={snakbar.show}
        autoHideDuration={snakbar.duration}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity={snakbar.type}>
          {snakbar.message}
        </Alert>
      </Snackbar>
    </SocketContext.Provider>
  );
}
