// component

import { functions } from "lodash";
import Iconify from "../../components/Iconify";
import { useEffect, useState } from "react";
import { useAppContext } from "src/hooks";

// ----------------------------------------------------------------------
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(
      array,

      (_user) =>
        _user?.title?.toLowerCase()?.indexOf(query?.toLowerCase()) !== -1 ||
        _user?.address?.toLowerCase()?.indexOf(query?.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
const getIcon = (name) => <Iconify icon={name} width={20} height={20} />;

const NavSelector = () => {
  const { _get_user_profile } = useAppContext();
  const profile = _get_user_profile();
  const [sidebarPermissions, setSidebarPermissions] = useState(
    profile?.privileges
  );
  const [sidebarBadge, setSidebarBadge] = useState(profile?.sidebar_status);
  const [sidebarCount, setSidebarCount] = useState(profile?.pending_leaves);
  const [roles, setRoles] = useState(profile?.role);

  // console.log(sidebarBadge, "sidebarBadgesidebarBadge");

  useEffect(() => {
    setSidebarPermissions(profile?.privileges);
    setSidebarBadge(profile?.sidebar_status);
    setSidebarCount(profile?.pending_leaves);
    setRoles(profile?.role);
  }, [
    profile?.privileges,
    profile?.sidebar_status,
    profile?.pending_leaves,
    profile?.role,
  ]);
  useEffect(() => {
    // console.log(sidebarBadge, "tested");
  }, [sidebarBadge]);
  const Selector = [
    {
      title: "dashboard",
      path: "/dashboard",
      badge: false,
      icon: getIcon("eva:pie-chart-2-fill"),
    },
  ];

  if (sidebarPermissions?.my_attendance?.sidebar) {
    Selector.push({
      title: "My Attendance",
      path: "/my-attendance",
      badge: false,
      icon: getIcon("heroicons-outline:presentation-chart-line"),
    });
  }
  if (sidebarPermissions?.employee?.sidebar) {
    Selector.push({
      title: "Team Members",
      path: "/team-member",
      icon: getIcon("fe:users"),
      badge: false,
    });
  }
  if (sidebarPermissions?.my_team?.sidebar) {
    Selector.push({
      title: "My Team",
      path: "/my-team-member",
      icon: getIcon("fluent:people-team-add-24-filled"),
      badge: false,
    });
  }
  if (sidebarPermissions?.role?.sidebar) {
    Selector.push({
      title: "Roles",
      path: "/roles",
      badge: false,
      icon: getIcon("carbon:user-role"),
    });
  }
  if (sidebarPermissions?.leaves?.sidebar) {
    Selector.push({
      title: "All Leaves",
      path: "/admin-leave",
      badge: sidebarBadge?.leaves ? true : false,
      icon: getIcon("majesticons:door-exit"),
      children: [
        {
          title: "Pending Leave",
          path: "/pending-admin-leave",
          count: sidebarCount,
          icon: getIcon("mdi:beach"),
        },
        {
          title: "Leaves",
          path: "/approved-admin-leave",

          icon: getIcon("mdi:beach"),
        },
      ],
      /* "children" key can be added in these objects, example:children:[{...}] */
    });
  }
  if (
    roles?.title == "HR" ||
    roles?.title == "Admin" ||
    roles?.title == "All"
  ) {
    Selector.push({
      title: "Reports",
      path: "/reporting",
      // badge: sidebarBadge?.leaves ? true : false,
      icon: getIcon("carbon:report"),
      children: [
        {
          title: "Leaves Report",
          path: "/leave-maintenance-list",
          icon: getIcon("mdi:beach"),
        },
      ],
      /* "children" key can be added in these objects, example:children:[{...}] */
    });
  }

  if (sidebarPermissions?.my_leaves?.sidebar) {
    Selector.push({
      title: "My Leaves",
      path: "/leaves",
      badge: sidebarBadge?.my_leaves ? true : false,
      icon: getIcon("mdi:beach"),
      /* "children" key can be added in these objects, example:children:[{...}] */
    });
  }
  if (sidebarPermissions?.loans?.sidebar) {
    Selector.push({
      title: "Loan ",
      path: "/loan",
      badge: sidebarBadge?.loans ? true : false,
      icon: getIcon("game-icons:pay-money"),
      /* "children" key can be added in these objects, example:children:[{...}] */
    });
  }
  if (sidebarPermissions?.my_loans?.sidebar) {
    Selector.push({
      title: "My Loan",
      path: "/my-loan-request",
      badge: sidebarBadge?.my_loans ? true : false,
      icon: getIcon("solar:hand-money-linear"),
      /* "children" key can be added in these objects, example:children:[{...}] */
    });
  }
  if (sidebarPermissions?.lunch?.sidebar) {
    Selector.push({
      title: "Lunch",
      path: "/lunch",
      badge: sidebarBadge?.lunch ? true : false,
      icon: getIcon("emojione-monotone:pot-of-food"),
      /* "children" key can be added in these objects, example:children:[{...}] */
    });
  }
  if (sidebarPermissions?.fine_sheet?.sidebar) {
    Selector.push({
      title: "Fines",
      path: "/fine",
      badge: sidebarBadge?.fine_sheet ? true : false,
      icon: getIcon("carbon:websheet"),
      /* "children" key can be added in these objects, example:children:[{...}] */
    });
  }
  if (sidebarPermissions?.allowance?.sidebar) {
    Selector.push({
      title: "Allowances",
      path: "/allowances",
      badge: sidebarBadge?.allowance ? true : false,
      icon: getIcon("carbon:event"),
    });
  }
  // if (sidebarPermissions?.tax_slabs?.sidebar) {
  //   Selector.push({
  //     title: "Tax Slabs",
  //     path: "/tax-slabs",
  //     badge: sidebarBadge?.tax_slabs ? true : false,
  //     icon: getIcon("marketeq:tax"),
  //   });
  // }

  if (sidebarPermissions?.payroll?.sidebar) {
    Selector.push({
      title: "Payroll",
      path: "/payroll",
      badge: sidebarBadge?.payroll ? true : false,
      icon: getIcon("simple-icons:amazonpay"),
      /* "children" key can be added in these objects, example:children:[{...}] */
    });
  }

  if (sidebarPermissions?.department?.sidebar) {
    Selector.push({
      title: "Departments",
      path: "/department",
      badge: false,
      icon: getIcon("tabler:git-branch"),
    });
  }
  if (sidebarPermissions?.company?.sidebar) {
    Selector.push({
      title: "Companies",
      path: "/companies",
      badge: false,
      icon: getIcon("arcticons:company-portal"),
    });
  }
  if (sidebarPermissions?.attendance?.sidebar) {
    Selector.push({
      title: "Attendance",
      path: "/attendance",
      badge: false,
      icon: getIcon("fluent:presence-away-24-filled"),
    });
  }
  if (sidebarPermissions?.announcement?.sidebar) {
    Selector.push({
      title: "Announcements",
      path: "/announcement",
      badge: sidebarBadge?.announcement ? true : false,

      icon: getIcon("mdi:announcement-outline"),
    });
  }

  if (
    sidebarPermissions?.upcomming_anniversary?.sidebar ||
    sidebarPermissions?.upcomming_birthdays?.sidebar ||
    sidebarPermissions?.upcomming_increments?.sidebar
  ) {
    Selector.push({
      title: "Events",
      path: "/events",
      icon: getIcon("mdi:event-star"),
      children: [],
    });
  }

  if (
    sidebarPermissions?.asset?.sidebar ||
    sidebarPermissions?.brand?.sidebar ||
    sidebarPermissions?.category?.sidebar ||
    sidebarPermissions?.vendor?.sidebar
  ) {
    Selector.push({
      title: "Company Assets",
      path: "/all-assets",
      icon: getIcon("fluent:web-asset-24-filled"),
      children: [],
    });
  }
  if (
    sidebarPermissions?.expense?.sidebar ||
    sidebarPermissions?.expense_category?.sidebar
  ) {
    Selector.push({
      title: "Expense",
      path: "/expenses",
      icon: getIcon("game-icons:money-stack"),
      children: [
        // {
        //   title: "Create Assets",
        //   path: "/create-assets",
        //   icon: getIcon("la:map-marked"),
        // },
        // sidebarPermissions?.category?.sidebar && {
        //   title: "Assets Categories ",
        //   path: "/assets-categories",
        //   icon: getIcon("la:map-marked"),
        // },
        // sidebarPermissions?.vendor?.sidebar && {
        //   title: "Vendors",
        //   path: "/assets-vendors",
        //   icon: getIcon("la:map-marked"),
        // },
        // {
        //   title: "Brands ",
        //   path: "/assets-brands",
        //   icon: getIcon("la:map-marked"),
        // },
      ],
    });
  }

  if (
    sidebarPermissions?.feedback_template?.sidebar ||
    sidebarPermissions?.feedback?.sidebar
  ) {
    Selector.push({
      title: "Teams Feedback",
      path: "/feedbackss",
      icon: getIcon("ic:round-feedback"),
      children: [],
    });
  }
  ///===========Done portal integration================

  // Selector.push(
  //   {
  //     title: "calendar",
  //     path: "/calendar",
  //     badge: false,
  //     icon: getIcon("bx:calendar"),
  //   },
  //   {
  //     title: "workspaces",
  //     path: "/workspaces",
  //     badge: false,
  //     icon: getIcon("bi:boxes"),
  //   },
  //   {
  //     title: "All Tasks",
  //     path: "/all-tasks",
  //     badge: false,
  //     icon: getIcon("fluent:clipboard-more-20-regular"),
  //   },
  //   {
  //     title: "All Completed Tasks",
  //     path: "/all-completed-tasks",
  //     badge: false,
  //     icon: getIcon("akar-icons:circle-check"),
  //   },
  //   {
  //     title: "invitations",
  //     path: "/invitations",
  //     badge: false,
  //     icon: getIcon("system-uicons:mail-add"),
  //   },
  //   {
  //     title: "team",
  //     path: "/dteam",
  //     badge: false,
  //     icon: getIcon("ri:team-fill"),
  //   }
  // );

  /////////////////////////////////////////////////////
  if (sidebarPermissions?.support?.sidebar) {
    Selector.push({
      title: "Support",
      path: "/support-tickets",
      icon: getIcon("mdi:support"),
      badge: sidebarBadge?.supports ? true : false,
    });
  }
  if (sidebarPermissions?.my_support?.sidebar) {
    Selector.push({
      title: "My Support",
      path: "/my-support-tickets",
      icon: getIcon("fluent:person-support-24-filled"),
      badge: sidebarBadge?.my_supports ? true : false,
    });
  }
  if (sidebarPermissions?.feedback_and_improvements?.sidebar) {
    Selector.push({
      title: "Feedback Improvement",
      path: "/improvement-feedback",
      icon: getIcon("fluent:person-feedback-20-filled"),
      badge: sidebarBadge?.feedback_and_improvements ? true : false,
    });
  }

  if (
    sidebarPermissions?.upcomming_anniversary?.sidebar ||
    sidebarPermissions?.upcomming_birthdays?.sidebar ||
    sidebarPermissions?.upcomming_increments?.sidebar
  ) {
    Selector.map((item, index) => {
      if (item.title == "Events") {
        if (sidebarPermissions?.upcomming_birthdays?.sidebar) {
          Selector[index].children.push({
            title: "Upcoming Birthday",
            path: "/upcoming-birthday",
            icon: getIcon("la:map-marked"),
          });
        }
        if (sidebarPermissions?.upcomming_anniversary?.sidebar) {
          Selector[index].children.push({
            title: "Upcoming Anniversary",
            path: "/upcoming-anniversary",
            icon: getIcon("la:map-marked"),
          });
        }
        if (sidebarPermissions?.upcomming_increments?.sidebar) {
          Selector[index].children.push({
            title: "Upcoming Increment",
            path: "/upcoming-increment",
            icon: getIcon("la:map-marked"),
          });
        }
      }
    });
  }

  if (
    sidebarPermissions?.asset?.sidebar ||
    sidebarPermissions?.brand?.sidebar ||
    sidebarPermissions?.category?.sidebar ||
    sidebarPermissions?.vendor?.sidebar
  ) {
    Selector.map((item, index) => {
      if (item.title == "Company Assets") {
        // console.log(item, index, "Theserching");
        if (sidebarPermissions?.asset?.sidebar) {
          Selector[index].children.push({
            title: "All Assets",
            path: "/company-assets",
            icon: getIcon("la:map-marked"),
          });
        }
        if (sidebarPermissions?.category?.sidebar) {
          Selector[index].children.push({
            title: "Assets Categories ",
            path: "/assets-categories",
            icon: getIcon("la:map-marked"),
          });
        }
        if (sidebarPermissions?.vendor?.sidebar) {
          Selector[index].children.push({
            title: "Vendors",
            path: "/assets-vendors",
            icon: getIcon("la:map-marked"),
          });
        }
        if (sidebarPermissions?.brand?.sidebar) {
          Selector[index].children.push({
            title: "Brands ",
            path: "/assets-brands",
            icon: getIcon("la:map-marked"),
          });
        }
      }
    });
  }
  if (
    sidebarPermissions?.expense?.sidebar ||
    sidebarPermissions?.expense_category?.sidebar
  ) {
    Selector.map((item, index) => {
      if (item.title == "Expense") {
        // console.log(item, index, "Theserching");
        if (sidebarPermissions?.expense?.sidebar) {
          Selector[index].children.push({
            title: "Top Up",
            path: "/top-up",
            icon: getIcon("la:map-marked"),
          });
        }
        if (sidebarPermissions?.expense?.sidebar) {
          Selector[index].children.push({
            title: "Expense",
            path: "/expense",
            icon: getIcon("la:map-marked"),
          });
        }
        if (sidebarPermissions?.expense_category?.sidebar) {
          Selector[index].children.push({
            title: "Expense Category",
            path: "/expenses_category",
            icon: getIcon("la:map-marked"),
          });
        }
      }
    });
  }

  if (
    sidebarPermissions?.feedback_template?.sidebar ||
    sidebarPermissions?.feedback?.sidebar
  ) {
    Selector.map((item, index) => {
      if (item.title == "Teams Feedback") {
        // console.log(item, index, "Theserching");
        if (sidebarPermissions?.feedback_template?.sidebar) {
          Selector[index].children.push({
            title: "Feedback Template",
            path: "/feedbacks-templates",
            badge: sidebarBadge?.feedback_template ? true : false,
            icon: getIcon("ic:round-feedback"),
            /* "children" key can be added in these objects, example:children:[{...}] */
          });
        }
        if (sidebarPermissions?.feedback?.sidebar) {
          Selector[index].children.push({
            title: "Feedback",
            path: "/feedback",
            badge: sidebarBadge?.feedback_template ? true : false,
            icon: getIcon("simple-icons:reacthookform"),
            /* "children" key can be added in these objects, example:children:[{...}] */
          });
        }
      }
    });
  }

  // if (roles?.title == "Admin" || roles?.title == "All") {
  if (sidebarPermissions?.general_settings?.sidebar) {
    Selector.push({
      title: "General Settings",
      path: "/general-settings",
      icon: getIcon("ant-design:setting-outlined"),
    });
  }

  if (sidebarPermissions?.tax_calculator?.sidebar) {
    Selector.push({
      title: "Tax Calculator",
      path: "/calculator",
      badge: sidebarBadge?.tax_calculator ? true : false,
      icon: getIcon("ri:calculator-line"),
    });
  }

  return Selector;
};

const navConfig = NavSelector;
// [
//   {
//     title: "dashboard",
//     path: "/dashboard",
//     icon: getIcon("eva:pie-chart-2-fill"),
//     /* "children" key can be added in these objects, example:children:[{...}] */
//   },
// ];
//
export default navConfig;
