import { invokeApi } from "src/utils";

export const teamNotification = async (limit) => {
  const requestObj = {
    path: `api/notification/get_notifications?limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const teamNotificationRead = async (id) => {
  const requestObj = {
    path: `api/notification/mark_notification_as_read/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const teamNotificationAllRead = async () => {
  const requestObj = {
    path: `api/notification/mark_all_notifications_as_read`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
