import { invokeApi } from "src/utils";
export const getAllTeamMembersList = async (page, rows, search, status) => {
  const requestObj = {
    path: `api/employee/get_employees?search=${search}&active_status=${status}&page=${page}&limit=${rows}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getAllTeamMembersListNew = async (page, rows, search, status) => {
  const requestObj = {
    path: `api/employee/get_employees_v1?search=${search}&active_status=${status}&page=${page}&limit=${rows}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getDepartmentsList = async () => {
  const requestObj = {
    path: `api/department/get_active_departments`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getTeamMemberDetail = async (id) => {
  const requestObj = {
    path: `api/employee/detail_employee_by_id/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _detail_employee_by_id_for_settings = async (id) => {
  const requestObj = {
    path: `api/employee/detail_employee_by_id_for_settings/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _detail_employee_by_id_popup = async (id) => {
  const requestObj = {
    path: `api/employee/detail_employee_by_id_popup/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const addTeamMember = async (data) => {
  const requestObj = {
    path: `api/employee/add_employee`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const editTeamMembers = async (_id, data) => {
  const requestObj = {
    path: `api/employee/edit_employee/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _edit_employee_for_settings = async (_id, data) => {
  const requestObj = {
    path: `api/employee/edit_employee_for_settings/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteTeamMember = async (_id) => {
  const requestObj = {
    path: `api/employee/delete_employee/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const addTeamMemberToDeactiveList = async (data) => {
  const requestObj = {
    path: `api/employee_deactivation/add_employee_deactivation`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addTeamMemberAttendance = async (data) => {
  const requestObj = {
    path: `api/attendance/get_attendance_for_employee`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getTeamMembersWithoutSearch = async (data) => {
  const requestObj = {
    path: `api/employee/get_all_employees`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getTeamMembersWithoutSearchAndInActiveMembers = async (data) => {
  const requestObj = {
    path: `api/employee/get_active_employees`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_member_list_for_my_team = async () => {
  const requestObj = {
    path: `api/employee/get_member_list_for_my_team`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_active_employees_for_loan = async (value) => {
  const requestObj = {
    path: `api/employee/get_active_employees_for_loan?search=${value}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const markAsDiscount = async (data) => {
  const requestObj = {
    path: `api/attendance/mark_discount_minutes`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getAbsentsDetails = async (page, rows, data) => {
  const requestObj = {
    path: `api/attendance/get_employee_absents?page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _get_employee_leaves = async (page, rows, data) => {
  const requestObj = {
    path: `api/attendance/get_employee_leaves?page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_data_for_add_employee = async () => {
  const requestObj = {
    path: `api/dashboard/get_data_for_add_employee`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _list_increment_history = async (data) => {
  const requestObj = {
    path: `api/increment_history/list_increment_history`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_increment_history = async (data) => {
  const requestObj = {
    path: `api/increment_history/add_increment_history`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _update_increment_history = async (_id, data) => {
  const requestObj = {
    path: `api/increment_history/update_increment_history/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _delete_increment_history = async (_id) => {
  const requestObj = {
    path: `api/increment_history/delete_increment_history/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _update_employee_allowance = async (_id, data) => {
  const requestObj = {
    path: `api/employee/update_employee_allowance/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_employee_allowance = async (id) => {
  const requestObj = {
    path: `api/employee/get_employee_allowance/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
