import { Navigate, useRoutes } from "react-router-dom";
import { TaskLinkController } from "src/doneSrc//components";
import Loadable from "react-loadable";

import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import Loader from "./doneSrc/components/loaders/CircularLoader";
import Calculator from "./pages/Calculator/Calculator";

const TeamMemberIncrement = Loadable({
  loader: () => import("./pages/teamMember/TeamMemberIncrement"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const Allowances = Loadable({
  loader: () => import("./pages/Allowances/Allowances"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const TaxSlabs = Loadable({
  loader: () => import("./pages/taxSlabs/TaxSlabs"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const DetailImprovement = Loadable({
  loader: () => import("./pages/FeedbackImprovement/DetailImprovement"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const EditImprovement = Loadable({
  loader: () => import("./pages/FeedbackImprovement/EditImprovement"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const AddImprovement = Loadable({
  loader: () => import("./pages/FeedbackImprovement/AddImprovement"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const FeedbackImprovement = Loadable({
  loader: () => import("./pages/FeedbackImprovement/FeedbackImprovement"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const TeamLeave = Loadable({
  loader: () => import("./pages/teamMember/TeamLeave"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const AdminLeavePendings = Loadable({
  loader: () => import("./pages/adminLeaves/AdminLeavePendings"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const LeaveMaintenance = Loadable({
  loader: () => import("./pages/adminLeaves/LeaveMaintenance"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const LeaveMaintenanceList = Loadable({
  loader: () => import("./pages/adminLeaves/LeaveMaintenanceList"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const FeedbacksRelated = Loadable({
  loader: () => import("./pages/feedbacks/component/FeedbacksRelated"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const LoanDetails = Loadable({
  loader: () => import("./pages/adminLoan/loanDetails"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const EditLoan = Loadable({
  loader: () => import("./pages/adminLoan/editLoan"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const AddLoan = Loadable({
  loader: () => import("./pages/adminLoan/addLoan"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const Loan = Loadable({
  loader: () => import("./pages/adminLoan/Loan"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const LoanRequestDetails = Loadable({
  loader: () => import("./pages/loan/loanRequestDetails"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const EditLoanRequest = Loadable({
  loader: () => import("./pages/loan/editLoanRequest"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const AddLoanRequest = Loadable({
  loader: () => import("./pages/loan/addLoanRequest"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const LoanRequest = Loadable({
  loader: () => import("./pages/loan/LoanRequest"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const FeedbackProgressHistory = Loadable({
  loader: () => import("./pages/feedbacks/component/FeedbackProgressHistory"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const OtherTeams = Loadable({
  loader: () => import("./pages/myTeamMember/OtherTeams"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const MyTeamMembers = Loadable({
  loader: () => import("./pages/myTeamMember/MyTeamMembers"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const AddUser = Loadable({
  loader: () => import("./pages/add-user/AddUser"),
  ssr: true,
  // delay: 100,
  timeout: 50,
  loading: Loader,
  // fallback: <Loader />
});

const EditPay = Loadable({
  loader: () => import("./pages/payroll/editPay"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const Pay = Loadable({
  loader: () => import("./pages/payroll/pay"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const PayRoll = Loadable({
  loader: () => import("./pages/payroll/PayRoll"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const Lunch = Loadable({
  loader: () => import("./pages/lunch/Lunch"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const FineDetails = Loadable({
  loader: () => import("./pages/dashboard/components/FineDetails"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const Dashboard = Loadable({
  loader: () => import("./pages/dashboard/Dashboard"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const ForgotPassword = Loadable({
  loader: () => import("./pages/forgot-password/ForgotPassword"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const Login = Loadable({
  loader: () => import("./pages/login/Login"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const Page404 = Loadable({
  loader: () => import("./pages/page-404/Page404"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const Profile = Loadable({
  loader: () => import("./pages/profile/Profile"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const Register = Loadable({
  loader: () => import("./pages/register/Register"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const Settings = Loadable({
  loader: () => import("./pages/settings/Settings"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const Users = Loadable({
  loader: () => import("./pages/users/Users"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const Roles = Loadable({
  loader: () => import("./pages/roles/Roles"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const Expense = Loadable({
  loader: () => import("./pages/expance/Expense"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const ExpenseCatagory = Loadable({
  loader: () => import("./pages/expanceCatagory/ExpenseCatagory"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const AddExpense = Loadable({
  loader: () => import("./pages/expance/addExpense"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const EditExpense = Loadable({
  loader: () => import("./pages/expance/editExpense"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const DetailsOfExpense = Loadable({
  loader: () => import("./pages/expance/expenseDetails"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const Announcement = Loadable({
  loader: () => import("./pages/announcement/Announcement"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const AddAnnouncement = Loadable({
  loader: () => import("./pages/announcement/addAnnouncement"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const EditAnnouncement = Loadable({
  loader: () => import("./pages/announcement/editAnnouncement"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const DetailsOfAnnouncement = Loadable({
  loader: () => import("./pages/announcement/detailsOfAnnouncement"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
// import {
//   AddUser,
//   Dashboard,
//   ForgotPassword,
//   Login,
//   Page404,
//   Profile,
//   Register,
//   Settings,
//   Users,
//   Roles,
// } from "./pages";
const Department = Loadable({
  loader: () => import("./pages/department/Department"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const TeamMember = Loadable({
  loader: () => import("./pages/teamMember/TeamMember"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const Company = Loadable({
  loader: () => import("./pages/company/Company"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const CompanyAssets = Loadable({
  loader: () => import("./pages/company_assets/CompanyAssets"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const AddDepartment = Loadable({
  loader: () => import("./pages/department/addDepartment"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const EditDepartment = Loadable({
  loader: () => import("./pages/department/editDepartment"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const AddTeamMember = Loadable({
  loader: () => import("./pages/teamMember/addTeamMebers"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const EditTeamMember = Loadable({
  loader: () => import("./pages/teamMember/editTeamMembers"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const Attendance = Loadable({
  loader: () => import("./pages/attendance/Attendance"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const AddAttendance = Loadable({
  loader: () => import("./pages/attendance/addAttendance"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const LeavesteamMembers = Loadable({
  loader: () => import("./pages/teamMember/teamMemberAttandance"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const EditAttendance = Loadable({
  loader: () => import("./pages/attendance/editAttendance"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const InActiveEmp = Loadable({
  loader: () => import("./pages/inActiveEmp/InActiveEmp"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const AssetsCatagory = Loadable({
  loader: () => import("./pages/assetsCatagory/AssetsCatagory"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const AssetsBrand = Loadable({
  loader: () => import("./pages/assetsBrand/AssetsBrand"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const AssetsVendors = Loadable({
  loader: () => import("./pages/assetsVendors/AssetsVendors"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const CreatAssets = Loadable({
  loader: () => import("./pages/assetsCreation/CreatAssets"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const AddAsset = Loadable({
  loader: () => import("./pages/assetsCreation/addAsset"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const EditAsset = Loadable({
  loader: () => import("./pages/assetsCreation/editAsset"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const DetailsOfAsset = Loadable({
  loader: () => import("./pages/assetsCreation/detailsOfAssets"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const DetailsOfRepairAboutAssets = Loadable({
  loader: () => import("./pages/assetsCreation/history_maintenance"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const AddRepairAndMaintenance = Loadable({
  loader: () => import("./pages/assetsCreation/addRepair"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const EditRepairAndMaintenance = Loadable({
  loader: () => import("./pages/assetsCreation/editRepair"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const MyAttendance = Loadable({
  loader: () => import("./pages/myAttendance/MyAttendance"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const Events = Loadable({
  loader: () => import("./pages/Events/Events"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const UpcomingIncrement = Loadable({
  loader: () => import("./pages/Events/UpcomingIncrement"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const NewPassword = Loadable({
  loader: () => import("./pages/newPassword/NewPassword"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const LeaveRequest = Loadable({
  loader: () => import("./pages/leaves/LeaveRequest"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const AddLeaveRequest = Loadable({
  loader: () => import("./pages/leaves/addLeaveRequest"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const EditLeaveRequest = Loadable({
  loader: () => import("./pages/leaves/editLeaveRequest"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const FineList = Loadable({
  loader: () => import("./pages/fineCalculation/FineList"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const TopUp = Loadable({
  loader: () => import("./pages/expanceTopUp/TopUp"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const AddTopUp = Loadable({
  loader: () => import("./pages/expanceTopUp/AddTopUp"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const EditTopUp = Loadable({
  loader: () => import("./pages/expanceTopUp/EditTopUp"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const TopUpDetails = Loadable({
  loader: () => import("./pages/expanceTopUp/TopUpDetails"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const FeedbackTemplates = Loadable({
  loader: () => import("./pages/surveyTemplates/FeedbackTemplates"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const AddFeedbackTemplate = Loadable({
  loader: () => import("./pages/surveyTemplates/addFeedbackTemplate"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const EditFeedbackTemplate = Loadable({
  loader: () => import("./pages/surveyTemplates/editFeedbackTemplate"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const TemplatePreview = Loadable({
  loader: () => import("./pages/surveyTemplates/templatePreview"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const Feedbacks = Loadable({
  loader: () => import("./pages/feedbacks/Feedbacks"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
// import FeedbacksHistory from "./pages/feedbacks/component/FeedbacksHistory";

const FeedbacksHistory = Loadable({
  loader: () => import("./pages/feedbacks/component/FeedbacksHistory"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const FeedbackForm = Loadable({
  loader: () => import("./pages/feedbacks/feedbackform"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const FeedbackDetails = Loadable({
  loader: () => import("./pages/feedbacks/feedbackDetails"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
// import Department from "./pages/department/Department";
// import TeamMember from "./pages/teamMember/TeamMember";
// import Company from "./pages/company/Company";
// import CompanyAssets from "./pages/company_assets/CompanyAssets";s
// import AddDepartment from "./pages/department/addDepartment";
// import EditDepartment from "./pages/department/editDepartment";
// import AddTeamMember from "./pages/teamMember/addTeamMebers";
// import EditTeamMember from "./pages/teamMember/editTeamMembers";
// import Attendance from "./pages/attendance/Attendance";
// import AddAttendance from "./pages/attendance/addAttendance";
// import LeavesteamMembers from "./pages/teamMember/teamMemberLeaves";
// import EditAttendance from "./pages/attendance/editAttendance";
// import InActiveEmp from "./pages/inActiveEmp/InActiveEmp";
// import AssetsCatagory from "./pages/assetsCatagory/AssetsCatagory";
// import AssetsBrand from "./pages/assetsBrand/AssetsBrand";
// import AssetsVendors from "./pages/assetsVendors/AssetsVendors";
// import CreatAssets from "./pages/assetsCreation/CreatAssets";
// import AddAsset from "./pages/assetsCreation/addAsset";
// import EditAsset from "./pages/assetsCreation/editAsset";
// import DetailsOfAsset from "./pages/assetsCreation/detailsOfAssets";
// import DetailsOfRepairAboutAssets from "./pages/assetsCreation/history_maintenance";
// import AddRepairAndMaintenance from "./pages/assetsCreation/addRepair";
// import EditRepairAndMaintenance from "./pages/assetsCreation/editRepair";
// import MyAttendance from "./pages/myAttendance/MyAttendance";
// import NewPassword from "./pages/newPassword/NewPassword";
// import LeaveRequest from "./pages/leaves/LeaveRequest";
// import AddLeaveRequest from "./pages/leaves/addLeaveRequest";
// import EditLeaveRequest from "./pages/leaves/editLeaveRequest";

const AdminLeaveRequest = Loadable({
  loader: () => import("./pages/adminLeaves/AdminLeave"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const AddAdminLeaveRequest = Loadable({
  loader: () => import("./pages/adminLeaves/addAddminleave"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const EditAdminLeaveRequest = Loadable({
  loader: () => import("./pages/adminLeaves/editAdminleaves"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const SupportTickets = Loadable({
  loader: () => import("./pages/Support/SupportTickets"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const MySupportTickets = Loadable({
  loader: () => import("./pages/mySupport/SupportTickets"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const AddSupportMember = Loadable({
  loader: () => import("./pages/mySupport/addSupport"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const EditSupportMember = Loadable({
  loader: () => import("./pages/mySupport/editSupport"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const AddSupportAdmin = Loadable({
  loader: () => import("./pages/Support/addSupport"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const EditSupportAdmin = Loadable({
  loader: () => import("./pages/Support/editSupport"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const SupportTicketDetails = Loadable({
  loader: () => import("./pages/Support/SupportTicketDetails"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});
const MySupportTicketDetails = Loadable({
  loader: () => import("./pages/Support/MySupportTicketDetails"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const Fine = Loadable({
  loader: () => import("./pages/fineCalculation/finee"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const AddFeedback = Loadable({
  loader: () => import("./pages/feedbacks/addFeedback"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

const GeneralSettings = Loadable({
  loader: () => import("./pages/GeneralSettings/GeneralSettings"),
  // delay: 100,
  timeout: 50,
  loading: Loader,
});

// import AdminLeaveRequest from "./pages/adminLeaves/AdminLeave";
// import AddAdminLeaveRequest from "./pages/adminLeaves/addAddminleave";
// import EditAdminLeaveRequest from "./pages/adminLeaves/editAdminleaves";
// import SupportTickets from "./pages/Support/SupportTickets";

// import MySupportTickets from "./pages/mySupport/SupportTickets";
// import AddSupportMember from "./pages/mySupport/addSupport";
// import EditSupportMember from "./pages/mySupport/editSupport";
// import AddSupportAdmin from "./pages/Support/addSupport";
// import EditSupportAdmin from "./pages/Support/editSupport";
// import SupportTicketDetails from "./pages/Support/SupportTicketDetails";
// import Fine from "./pages/attendance/Fine";

// import done pages//////
// const TaskCalendar = Loadable({
//   loader: () => import("./doneSrc/pages/task-calendar/TaskCalendar"),
//   // delay: 100,
//   timeout: 50,
//   loading: Loader,
// });
// const Team = Loadable({
//   loader: () => import("./doneSrc/pages/team/Team"),
//   // delay: 100,
//   timeout: 50,
//   loading: Loader,
// });
// const AddTeam = Loadable({
//   loader: () => import("./doneSrc/pages/add-team/AddTeam"),
//   // delay: 100,
//   timeout: 50,
//   loading: Loader,
// });
// const WorkSpaces = Loadable({
//   loader: () => import("./doneSrc/pages/workspaces/WorkSpaces"),
//   // delay: 100,
//   timeout: 50,
//   loading: Loader,
// });
// const TargetDatePassedTasks = Loadable({
//   loader: () =>
//     import("./doneSrc/pages/target-date-passed-tasks/TargetDatePassedTasks"),
//   // delay: 100,
//   timeout: 50,
//   loading: Loader,
// });
// const AllTasks = Loadable({
//   loader: () => import("./doneSrc/pages/all-tasks/AllTasks"),
//   // delay: 100,
//   timeout: 50,
//   loading: Loader,
// });
// const AllCompletedTasks = Loadable({
//   loader: () => import("./doneSrc/pages/all-completed-tasks/AllCompletedTasks"),
//   // delay: 100,
//   timeout: 50,
//   loading: Loader,
// });
// const Invitations = Loadable({
//   loader: () => import("./doneSrc/pages/workspaces/Invitations"),
//   // delay: 100,
//   timeout: 50,
//   loading: Loader,
// });
// const CreateWorkspace = Loadable({
//   loader: () => import("./doneSrc/pages/workspaces/CreateWorkspace"),
//   // delay: 100,
//   timeout: 50,
//   loading: Loader,
// });
// const EditWorkspace = Loadable({
//   loader: () => import("./doneSrc/pages/workspaces/EditWorkspace"),
//   // delay: 100,
//   timeout: 50,
//   loading: Loader,
// });
// const WorkspaceDetail = Loadable({
//   loader: () => import("./doneSrc/pages/workspace-detail/WorkspaceDetail"),
//   // delay: 100,
//   timeout: 50,
//   loading: Loader,
// });
// const VerifiedTasks = Loadable({
//   loader: () => import("./doneSrc/pages/verified-tasks/VerifiedTasks"),
//   // delay: 100,
//   timeout: 50,
//   loading: Loader,
// });
// const WorkspaceProject = Loadable({
//   loader: () => import("./doneSrc/pages/workspace-project/WorkspaceProject"),
//   // delay: 100,
//   timeout: 50,
//   loading: Loader,
// });
// const CreateWorkspaceProject = Loadable({
//   loader: () =>
//     import("./doneSrc/pages/create-workspace-project/CreateWorkspaceProject"),
//   // delay: 100,
//   timeout: 50,
//   loading: Loader,
// });
// const Chat = Loadable({
//   loader: () => import("./doneSrc/pages/chat/Chat"),
//   // delay: 100,
//   timeout: 50,
//   loading: Loader,
// });
// const TeamMemberProfile = Loadable({
//   loader: () => import("./doneSrc/pages/member-profile/TeamMemberProfile"),
//   // delay: 100,
//   timeout: 50,
//   loading: Loader,
// });
// const EditWorkspaceProject = Loadable({
//   loader: () =>
//     import("./doneSrc/pages/edit-workspace-project/EditWorkspaceProject"),
//   // delay: 100,
//   timeout: 50,
//   loading: Loader,
// });
// ==========>

// import {
//   TaskCalendar,
//   Team,
//   AddTeam,
//   WorkSpaces,
//   TargetDatePassedTasks,
//   AllTasks,
//   AllCompletedTasks,
//   Invitations,
//   CreateWorkspace,
//   EditWorkspace,
//   WorkspaceDetail,
//   VerifiedTasks,
//   WorkspaceProject,
//   CreateWorkspaceProject,
//   EditWorkspaceProject,
//   Chat,
//   TeamMemberProfile,
// } from "src/doneSrc/pages";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "dashboard", element: <Dashboard /> },
        { path: "roles", element: <Roles /> },
        { path: "users", element: <Users /> },
        { path: "leaves", element: <LeaveRequest /> },
        { path: "leaves/add-leaves", element: <AddLeaveRequest /> },
        { path: "leaves/edit-leaves/:id", element: <EditLeaveRequest /> },
        { path: "approved-admin-leave", element: <AdminLeaveRequest /> },
        { path: "pending-admin-leave", element: <AdminLeavePendings /> },
        {
          path: "leave-maintenance-list/leave-maintenance/:id",
          element: <LeaveMaintenance />,
        },
        {
          path: "leave-maintenance-list",
          element: <LeaveMaintenanceList />,
        },
        {
          path: "admin-leave/admin-add-leave",
          element: <AddAdminLeaveRequest />,
        },
        {
          path: "admin-leave/admin-edit-leave/:id",
          element: <EditAdminLeaveRequest />,
        },
        { path: "users/add-user", element: <AddUser /> },
        { path: "profile", element: <Profile /> },
        { path: "settings", element: <Settings /> },
        { path: "new-password", element: <NewPassword /> },
        { path: "department", element: <Department /> },
        { path: "department/add-department", element: <AddDepartment /> },
        {
          path: "/department/edit-department/:id",
          element: <EditDepartment />,
        },
        { path: "team-member", element: <TeamMember /> },
        { path: "team-member/add-team-member", element: <AddTeamMember /> },
        { path: "in-active-member", element: <InActiveEmp /> },
        {
          path: "team-member/leaves-team-member/:id",
          element: <LeavesteamMembers />,
        },
        {
          path: "team-member/increment-team-member/:id",
          element: <TeamMemberIncrement />,
        },
        {
          path: "team-member/all-leaves-team-member/:id",
          element: <TeamLeave />,
        },

        {
          path: "team-member/edit-team-member/:id",
          element: <EditTeamMember />,
        },
        { path: "my-attendance", element: <MyAttendance /> },

        {
          path: "upcoming-birthday",
          element: <Events type="birthday" />,
        },
        {
          path: "upcoming-anniversary",
          element: <Events type="anniversary" />,
        },
        {
          path: "upcoming-increment",
          element: <UpcomingIncrement />,
        },

        { path: "allowances", element: <Allowances /> },
        // { path: "tax-slabs", element: <TaxSlabs /> },
        { path: "calculator", element: <Calculator /> },
        { path: "improvement-feedback", element: <FeedbackImprovement /> },
        {
          path: "improvement-feedback/add-improvement",
          element: <AddImprovement />,
        },
        {
          path: "improvement-feedback/edit-improvement/:id",
          element: <EditImprovement />,
        },
        {
          path: "improvement-feedback/detail-improvement/:id",
          element: <DetailImprovement />,
        },
        { path: "companies", element: <Company /> },
        { path: "company_assets", element: <CompanyAssets /> },
        { path: "attendance", element: <Attendance /> },
        { path: "attendance/add-attendance", element: <AddAttendance /> },
        { path: "fine/add-fine", element: <Fine /> },
        {
          path: "attendance/edit-attendance/:id",
          element: <EditAttendance />,
        },
        { path: "assets-categories", element: <AssetsCatagory /> },
        { path: "assets-brands", element: <AssetsBrand /> },
        { path: "assets-vendors", element: <AssetsVendors /> },
        { path: "company-assets", element: <CreatAssets /> },
        { path: "company-assets/create-assets", element: <AddAsset /> },

        { path: "company-assets/edit-asset/:id", element: <EditAsset /> },
        {
          path: "company-assets/details-asset/:id",
          element: <DetailsOfAsset />,
        },
        {
          path: "company-assets/repair_details-asset/:id",
          element: <DetailsOfRepairAboutAssets />,
        },
        {
          path: "company-assets/add-repair-details-asset/:id",
          element: <AddRepairAndMaintenance />,
        },
        {
          path: "company-assets/edit-repair-details-asset/:id",
          element: <EditRepairAndMaintenance />,
        },
        {
          path: "/support-tickets",
          element: <SupportTickets />,
        },
        {
          path: "/my-support-tickets",
          element: <MySupportTickets />,
        },
        {
          path: "/my-support-tickets/add-my-support-tickets",
          element: <AddSupportMember />,
        },
        {
          path: "/my-support-tickets/edit-my-support-tickets/:id",
          element: <EditSupportMember />,
        },
        {
          path: "/admin-support-tickets/add-admin-support-tickets",
          element: <AddSupportAdmin />,
        },
        {
          path: "/admin-support-tickets/edit-admin-support-tickets/:id",
          element: <EditSupportAdmin />,
        },
        {
          path: "/support_tickets/:ticket_id",
          element: <SupportTicketDetails />,
        },
        {
          path: "/my_support_tickets/:ticket_id",
          element: <MySupportTicketDetails />,
        },
        {
          path: "/expenses_category",
          element: <ExpenseCatagory />,
        },
        {
          path: "/expense",
          element: <Expense />,
        },
        {
          path: "expense/add-expense",
          element: <AddExpense />,
        },
        {
          path: "expense/edit-expense/:id",
          element: <EditExpense />,
        },
        {
          path: "expense/detail-expense/:id",
          element: <DetailsOfExpense />,
        },

        {
          path: "top-up",
          element: <TopUp />,
        },

        {
          path: "top-up/add-top-up",
          element: <AddTopUp />,
        },
        {
          path: "top-up/edit-top-up/:id",
          element: <EditTopUp />,
        },

        {
          path: "top-up/detail-top-up/:id",
          element: <TopUpDetails />,
        },
        {
          path: "/announcement",
          element: <Announcement />,
        },
        {
          path: "/announcement/add-announcement",
          element: <AddAnnouncement />,
        },
        {
          path: "/announcement/edit-announcement/:id",
          element: <EditAnnouncement />,
        },

        {
          path: "/announcement/details-announcement/:id",
          element: <DetailsOfAnnouncement />,
        },

        {
          path: "/fine",
          element: <FineList />,
        },
        {
          path: "/feedbacks-templates",
          element: <FeedbackTemplates />,
        },
        {
          path: "feedbacks-templates/add-feedbacks-templates",
          element: <AddFeedbackTemplate />,
        },
        {
          path: "feedbacks-templates/edit-feedbacks-templates/:id",
          element: <EditFeedbackTemplate />,
        },
        {
          path: "feedbacks-templates/preview-feedbacks-templates/:id",
          element: <TemplatePreview />,
        },

        {
          path: "feedback/add-feedback",
          element: <AddFeedback />,
        },
        {
          path: "feedback",
          element: <Feedbacks />,
        },
        {
          path: "feedback-history",
          element: <FeedbacksHistory />,
        },
        {
          path: "feedback/form-feedback/:id",
          element: <FeedbackForm />,
        },
        {
          path: "feedback/details-feedback/:id",
          element: <FeedbackDetails />,
        },
        {
          path: "feedback-related",
          element: <FeedbacksRelated />,
        },

        {
          path: "details",
          element: <FineDetails />,
        },
        {
          path: "lunch",
          element: <Lunch />,
        },
        {
          path: "payroll",
          element: <PayRoll />,
        },
        {
          path: "payroll/add-pay-empl",
          element: <Pay />,
        },
        {
          path: "payroll/edit-pay/:id",
          element: <EditPay />,
        },
        {
          // path: "my-team-member",
          path: "my-team-member",
          element: <MyTeamMembers />,
        },
        {
          path: "my-team-member/other-teams",
          element: <OtherTeams />,
        },
        {
          path: "feedback-progress-history",
          element: <FeedbackProgressHistory />,
        },
        {
          path: "my-loan-request",
          element: <LoanRequest />,
        },
        {
          path: "my-loan-request-add-loan",
          element: <AddLoanRequest />,
        },
        {
          path: "my-loan-request-Edit-loan/:id",
          element: <EditLoanRequest />,
        },
        {
          path: "my-loan-request-details-loan/:id",
          element: <LoanRequestDetails />,
        },
        {
          path: "loan",
          element: <Loan />,
        },
        {
          path: "loan-add-loan",
          element: <AddLoan />,
        },
        {
          path: "loan-Edit-loan/:id",
          element: <EditLoan />,
        },
        {
          path: "loan-details-loan/:id",
          element: <LoanDetails />,
        },

        {
          path: "general-settings/",
          element: <GeneralSettings />,
        },

        ///====================done paths====================
        // { path: "calendar", element: <TaskCalendar /> },
        // { path: "dteam", element: <Team /> },
        // { path: "team/add-team", element: <AddTeam /> },

        // { path: "workspaces", element: <WorkSpaces /> },
        // {
        //   path: "target-date-passed-tasks",
        //   element: <TargetDatePassedTasks />,
        // },
        // {
        //   path: "all-tasks",
        //   element: <AllTasks />,
        // },
        // {
        //   path: "all-completed-tasks",
        //   element: <AllCompletedTasks />,
        // },
        // { path: "invitations", element: <Invitations /> },
        // { path: "workspaces/create", element: <CreateWorkspace /> },
        // { path: "workspaces/edit/:workspace_id", element: <EditWorkspace /> },
        // { path: "workspaces/:id", element: <WorkspaceDetail /> },
        // {
        //   path: "workspaces/:workspace_id/:project_id",
        //   element: <VerifiedTasks />,
        // },
        // { path: "workspaces/projects/:id", element: <WorkspaceProject /> },
        // {
        //   path: "workspaces/create-project",
        //   element: <CreateWorkspaceProject />,
        // },
        // {
        //   path: "workspaces/edit-project/:id",
        //   element: <EditWorkspaceProject />,
        // },
        // { path: "chat", element: <Chat /> },
        // { path: "member-profile/:id", element: <TeamMemberProfile /> },
        // {
        //   path: "workspaces/copied-task-link/:project_id/:section_id/:task_id",
        //   element: <TaskLinkController />,
        // },

        ///////////////////////end//////////////////////////////

        ,
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
