import React, {
  useState,
  useEffect,
  useTransition,
  useLayoutEffect,
} from "react";
import { get_from_localStorage } from "src/utils/LocalStorage";

const Context = React.createContext();
const AppContext = ({ children }) => {
  const [profile, setProfile] = useState(
    localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : ""
  );
  const [searchValue, setSearchValue] = useState("");
  const [currentSelectedItem, setCurrentSelectedItem] = useState(undefined);
  const [globalSearchVisible, setGlobalSearchVisible] = useState(false);
  const [isPending, startTransition] = useTransition();
  //------------------------------------------------------
  const dispatch_get_user_profile = () => {
    return localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
  };
  const dispatch_set_user_profile = (value) => {
    setProfile(value);
  };
  const dispatch_get_search_value = () => {
    return searchValue;
  };
  const dispatch_set_search_value = (value) => {
    setSearchValue(value);
  };
  const dispatch_get_current_selected_item = () => {
    return currentSelectedItem;
  };
  const dispatch_set_current_selected_item = (value) => {
    setCurrentSelectedItem(value);
  };
  const dispatch_get_search_visibility = () => {
    return globalSearchVisible;
  };
  const dispatch_set_search_visibility = (value) => {
    setGlobalSearchVisible(value);
  };
  //------------------------------------------------------
  useLayoutEffect(() => {
    if (get_from_localStorage("user")) {
      setProfile(get_from_localStorage("user"));
    }
    return () => {
      //cleanup
    };
  }, []);
  //------------------------------------------------------
  const _get_user_profile = () => {
    return profile;
  };
  const _set_user_profile = (value) => {
    setProfile(value);
  };

  //------------------------------------------------------

  const getUserData = async () => {
    let user = localStorage.getItem("user");
    let type = localStorage.getItem("type");
    let role = localStorage.getItem("role");
    user = JSON.parse(user);
    let privilege = localStorage.getItem("previllages");
    privilege = JSON.parse(privilege);
    // console.log(user, "dskjfjas");
    // profile.first_name = user.first_name;
    // profile.last_name = user.last_name;
    // profile.email = user.user_id.email;
    // profile.image = user.profile_image;
    // profile.privileges = privilege;
    // // ({ ...profile });
    setProfile((values) => ({
      ...values,
      // ...get_from_localStorage("user")
      ["email"]: user?.user_id?.email,
      ["user_id"]: user?.user_id?._id,
      ["id"]: user?._id,
      ["full_name"]: user?.full_name,
      ["privileges"]: privilege,
      ["image"]: user?.profile_pic?.small,
      ["type"]: type,
      ["role"]: user?.role,
      ["sidebar_status"]: user?.sidebar_status,
      ["pending_leaves"]: user?.pending_leaves,
      ["basic_salary"]: user?.basic_salary,
      ["general_settings"]: user?.general_settings,
    }));
  };
  // useEffect(() => {
  //   console.log(profile, "lkflkasdlkajsdls");
  // }, [profile]);
  useEffect(() => {
    getUserData();

    return () => {};
  }, []);
  //------------------------------------------------------
  const bundle = {
    profile,
    _get_user_profile,
    _set_user_profile,
    dispatch_get_user_profile,
    dispatch_set_user_profile,
    dispatch_get_search_value,
    dispatch_set_search_value,
    dispatch_get_current_selected_item,
    dispatch_set_current_selected_item,
    dispatch_get_search_visibility,
    dispatch_set_search_visibility,
  };
  return <Context.Provider value={bundle}>{children}</Context.Provider>;
};

export default AppContext;
export const useAppContext = () => React.useContext(Context);
