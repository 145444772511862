import { invokeApi } from "src/utils";
export const getAllFeedbackList = async (page, rows, search) => {
  const requestObj = {
    path: `api/feedback/search_feedback?page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: search,
  };

  return invokeApi(requestObj);
};
export const getAllFeedbackHistoryList = async (page, rows, search) => {
  const requestObj = {
    path: `api/feedback/submitted_feedbacks?page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: search,
  };
  return invokeApi(requestObj);
};

export const getAllFeedbackRelatedList = async (page, rows, search) => {
  const requestObj = {
    path: `api/feedback/search_feedbacks_for_me?page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: search,
  };
  return invokeApi(requestObj);
};
export const getAllFeedbackProgressHistoryList = async (page, rows, search) => {
  const requestObj = {
    path: `api/feedback/get_performance?page=${page}&limit=${rows}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: search,
  };
  return invokeApi(requestObj);
};
export const getFeedbackDetail = async (id) => {
  const requestObj = {
    path: `api/feedback/detail_feedback/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const addFeedback = async (data) => {
  const requestObj = {
    path: `api/feedback/add_feedback`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getRelatedEmployes = async (data) => {
  const requestObj = {
    path: `api/employee/get_members_leads_for_each_other`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteFeeddback = async (_id) => {
  const requestObj = {
    path: `api/feedback/delete_feedback/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const submitForm = async (id, data) => {
  const requestObj = {
    path: `api/feedback/submit_feedback/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getTemplatesWithoutSearchAndInActive = async (data) => {
  const requestObj = {
    path: `api/feedback_template/get_feedback_template`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
